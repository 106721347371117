.kwn-comp-small-btn {
  margin-left: 1rem;
  width: 7rem;
  height: 2rem;
  border-radius: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-width: 1px; */
  /* border: 0.7px solid #006039; */
  cursor: pointer;
}

.kwn-comp-small-btn p {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  font-family: var(--font-family);
}

.kwn-comp-small-btn-weekly {
  margin-left: 0 !important;
  width: 2.5vw !important;
}

.kwn-comp-small-btn-weekly p {
  font-size: 7px;
}

@media screen and (max-width: 420px) {
  .kwn-comp-small-btn {
    margin-bottom: .5rem;
  }
}