/* General Styles */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 10px 40px;
    position: relative;
}

/* Logo Styles */
.kavan__navbar-links_logo {
    margin-right: 3.2rem;
}


.otpHeading{
    font-size: 20px;
}

.kavan__navbar-links_logo img {
    width: 15.4rem;
    height: 5.2rem;
    cursor: pointer;
    object-fit: contain;
}

/* Menu Box Styles */
.menu-box {
    display: flex;
    align-items: center;
    gap: 4px;
}

.menu-icon {
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    object-fit: contain;
}

/* Stripe Button Styles */
#stripe {
    background-color: #635bff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 40px;
    border-radius: 10px;
    color: white;
}

#stripe * {
    margin: 0;
    padding: 0;
    color: white;
    font-size: 18px;
    font-weight: 400;
    font-family: Satoshi;
}

/* Menu Text Styles */
.menu-text {
    color: #cccfce;
    font-family: var(--font-family);
    cursor: pointer;
    margin-left: 8px;
}

/* Navigation Links Container */
.nav-links {
    display: flex;
    gap: 50px;
}

/* Navigation Links Styles */
.nav-links a {
    text-decoration: none;
    font-size: 16px;
}

/* Navigation Links Hover Effect */
.nav-links a:hover {
    color: #006039;
}

/* Profile Icon Styles */
.profile-icon {
    width: 50px;
    height: 50px;
    cursor: pointer;
}

/* Profile Icon Image Styles */
.profile-icon img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

/* Dropdown Menu Styles */
.dropdown-menu {
    position: absolute;
    top: 60px; /* Adjust based on your needs */
    right: 0;
    margin-right: 18px;
    background-color: #fff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 4px;
    cursor: pointer;
    overflow: hidden;
}

.dropdown-menu a {
    color: #006039;
    padding: 14px 18px;
    text-decoration: none;
    font-family: var(--font-family);
    display: block;
    font-size: 15px;
    cursor: pointer;
}

/* Dropdown Menu Hover Effect */
.dropdown-menu a:hover {
    background-color: #ddd;
}

/* Hamburger Menu Styles */
.hamburger-menu {
    display: none;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
}

.hamburger-menu div {
    width: 30px;
    height: 3px;
    background-color: #000;
}

/* Mobile Menu Styles */
.mobile-menu {
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #fff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    z-index: 1;
}

.mobile-menu.hidden {
    display: none;
}

.mobile-menu a {
    color: #006039;
    padding: 10px;
    text-decoration: none;
    display: block;
    font-size: 18px;
    text-align: center;
}

/* Mobile Menu Hover Effect */
.mobile-menu a:hover {
    background-color: #ddd;
}

/* Media Query for Mobile View */
@media (max-width: 768px) {
    .nav-links {
        display: none;
    }

    .hamburger-menu {
        display: flex;
    }
}
