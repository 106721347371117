.kwn-appointment_view {
  margin-top: 3rem;
  width: 36.8rem;
  display: flex;
  flex-direction: row;
  background: #ffffff;
  box-shadow: 0px 0px 1.5rem rgba(57, 61, 72, 0.1);
  border-radius: 1.2rem;
}

.kwn-side_divider {
  width: 0.5rem;
  /* height: 3rem; */
  margin: 2rem 0rem;
  background: #006039;
  border-radius: 10px;
}

.kwn-appointment_content_top_view {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
}

.kwn-appointment_title_view {
  display: flex;
  justify-content: space-between;
}

.kwn-appointment_title_view h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  align-items: center;
  text-transform: uppercase;
  color: #2d292a;
}

.cancel-icon {
  margin-right: 12px;
  width: 33px;
  height: 33px;
}

.kwn-appointment_title_view img {
  width: 0.4rem;
  height: 1.8rem;
}

.kwn-appointment_time_view {
  display: flex;
  padding-top: 0.7rem;
}

.kwn-appointment_time_view h2 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  align-items: center;
  color: #666666;
}

.kwn-appointment_time_view img {
  width: 2rem;
  height: 2rem;
  margin-right: 1.5rem;
}

.kwn-appointment_divider {
  border: 0.5px solid #dadada;
  margin: 2rem 0rem;
}

.kwn-appointment_profile_view {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.kwn-appointment_profile_view img {
  width: 6rem;
  height: 6rem;
  border-radius: 3rem;
}

.kwn-appointment_name_view {
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem;
}

.kwn-appointment_name_view h3 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  text-transform: uppercase;
  color: #2d292a;
}

.kwn-appointment_name_view h4 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #666666;
  padding-top: 0.6rem;
}

.kwn-appointment_buttons_view {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  padding-top: 3rem;
}

.kwn-appointment_button {
  padding: 1rem 4.8rem;
  background: #ec4b4a;
  border-radius: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer
}

.kwn-appointment_button h3 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #ffffff;
}

.kwn-top_button {
  width: 10.4rem;
  height: 3rem;
  background: #006039;
  border-radius: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kwn-top_button h3 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: #ffffff;
}

.kwn-appointment_reason_view {
  padding-top: 2rem;
}

.kwn-appointment_reason_view h3 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  align-items: center;
  text-transform: uppercase;
  color: #2d292a;
}

.kwn-appointment_reason_view h4 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  align-items: center;
  padding-top: 0.6rem;
  color: #666666;
}

.kwn_three_dot_top_view {
  position: relative;
}

.kwn-three_dot_modal {
  position: absolute;
  width: 20.8rem;
  height: 12.8rem;
  top: 2.5rem;
  right: 0;
  background: #ffffff;
  box-shadow: 0px 0px 2rem rgba(0, 0, 0, 0.15);
  border-radius: 2rem;
  padding-top: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.kwn-three_dot_modal h2 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #2d292a;
  padding-bottom: 3rem;
  cursor: pointer;
}

/* --------Media Query--------- */

@media screen and (max-width: 550px) {
  .kwn-three_dot_modal {
    padding-top: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: 14.8rem;
    height: 8.8rem;
  }
  .kwn-three_dot_modal h2 {
    padding-bottom: 1.5rem;
  }
}

@media screen and (max-width: 450px) {
  .kwn-appointment_button {
    padding: 1rem 3.8rem;
  }

  .kwn-appointment_view {
    margin-top: 3rem;
    width: 34.8rem;
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 0px 0px 1.5rem rgba(57, 61, 72, 0.1);
    border-radius: 1.2rem;
  }  
}

@media screen and (max-width: 420px) {
  .kwn-appointment_time_view h2 {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
}

@media screen and (max-width: 410px) {
  .kwn-appointment_button {
    padding: 1rem 2.8rem;
  }
}




/* New Css??? */

.calendar-event * {
  font-family: Satoshi;
}

.calendar-event {
  border: 1px solid #ccc;
  -webkit-box-shadow: 5px 2px 16px -7px rgba(0,0,0,0.75);
  -moz-box-shadow: 5px 2px 16px -7px rgba(0,0,0,0.75);
  box-shadow: 5px 2px 16px -7px rgba(0,0,0,0.75);
  background-color: white;
  border-radius: 15px;
  padding-left: 2px;
}

.calendar-event-status-text > p {
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
  color: #666666;
  font-size: 12px;
}

.calendar-event-inner {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.event-person-detail {
  display: flex;
  flex-direction: column;
}

.event-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date-time {
  font-weight: bold;
  color: #6EA38C;
  font-size: 16px;
  /* width: 15.6rem; */
  width: 10vw;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: space-evenly;
  align-items: center;
}

.buttons div {
  width: 130px
}

.buttons button {
  height: 32px;
  width: max-content;
  min-width: 110px;
}

.accept-button, .reschedule-button, .cancel-button, .join-button {
  padding: 4px 12px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.join-button {
  background-color: #307B5A;
  color: white;
}

.accept-button {
  background-color: #f0a500;
  color: white;
}

.reschedule-button {
  border: 2px solid #307B5A;
  background-color: white;
  color: #307B5A;
}

.cancel-button {
  background-color: #ff3b30;
  color: white;
}

.event-details-main {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.event-details {
  display: flex;
  width: 300px;
  justify-content: space-between;
}

.avatar {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-right: 10px;
}

.event-name {
  font-weight: bold;
  font-size: 24px;
  color: #006039;
  font-family: Satoshi;
}