.kwn-session_ended-main_view {
  display: flex;
  flex-direction: column;
  margin-top: 9.8rem;
}

.kwn-session_ended-tick_view {
  width: 8.2rem;
  height: 8.2rem;
  background: #006039;
  border-radius: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
}

.kwn-session_ended-tick_view img {
  width: 3.8rem;
  height: 3.5rem;
}

.kwn-session_ended-main_view h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 3.2rem;
  line-height: 4.9rem;
  letter-spacing: -0.684615px;
  color: #2d292a;
}

.kwn-session_ended-main_view h3 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.9rem;
  line-height: 2.5rem;
  letter-spacing: -0.684615px;
  color: #666666;
  padding-top: 1rem;
}

.kwn-session_ended-user_detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5rem;
}

.kwn-session_ended-user_detail img {
  width: 10rem;
  height: 10rem;
  border-radius: 5rem;
  margin-right: 2rem;
}

.kwn-session_ended-user_detail h3 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.8rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #393d48;
}

.kwn-session_ended-user_detail h4 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  display: flex;
  align-items: center;
  color: #666666;
  padding-top: 1rem;
}

@media screen and (max-width: 450px) {
  .kwn-session_ended-main_view h2 {
    font-size: 2.6rem;
    line-height: 2.8rem;
  }
  .kwn-session_ended-user_detail h3 {
    font-size: 2.2rem;
    line-height: 2.4rem;
  }
}
