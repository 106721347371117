.chat-input {
    display: flex;
    align-items: center;
    flex: 2;
    padding: 10px;

}

.chat-input form {
    display: flex;
    width: 100%;

}

.chat-input input {
    flex: 1;
    padding: 14px;
    border-radius: 8px;
    border: none;
    outline: none;
    background-color: #f0f0f0;
    border-radius: 25px;
}

.inputContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.send-message-icon {
    height: 40px;
}

.send-message-mic {
    height: 30px;
    margin-right: 12px;
}

.chat-input button {
    display: none;
}