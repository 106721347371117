.kwn-verify_phone_number-Button_container p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #2d292a;
  margin-top: 9.7rem;
}
.kwn-verify_phone_number-Button_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.kwn-verify_phone_number-Button_container span {
  color: var(--color-theme);
  text-decoration: underline;
}
