.kwn-confirm_password-main_container {
    display: flex;
    align-items: center;
    padding-top: 8rem;
    padding-left: 9.6rem;
    padding-right: 9.6rem;
    padding-bottom: 10rem;
    flex-direction: column;
}

.kwn-confirm_password-title_container {
    margin-bottom: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.kwn-confirm_password-title_container h1 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 3.2rem;
    line-height: 4.9rem;
    color: #2d292a;
}

.kwn-confirm_password-title_container h2 {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.3rem;
    color: #2d292a;
    margin-top: 1rem;
}

.kwn-confirm_password-form_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.kwn-confirm_password-Button_container {
    padding-top: 2.5rem;
}

.kwn-confirm_password-main_container h3 {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 300;
    font-size: 2.6rem;
    line-height: 3.6rem;
    color: #2d292a;
    padding-top: 5rem;
}