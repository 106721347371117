.phone-input-wrapper {
    
    height: 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    border: 1px solid black;
    border-radius: 10px;
    /* padding: 5px; */
    justify-self: center;
    width: 100%;
    background-color: transparent;
    max-width: 500px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 25px;
  }
  
  .dropdown {
    flex: 0.1;
    border: none;
    background: transparent;
    font-size: 16px;
    padding: 5px;
    outline: none;
    text-align: center;
    background-color: transparent   ;
    display: flex;
    align-self: center;
    justify-self: center;
    margin: 0;
    /* margin-top: 20; */
  }
  
  .phone-input {
    flex: 0.9;
    border: none;
    outline: none;
    font-size: 16px;
    padding: 5px;
    background-color: transparent;
  }
  
  .phone-input-wrapper:hover {
    /* border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center; */
  }
  
  .phone-input-wrapper:focus-within {
    /* border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.5); */
  }
  
  .phone-input::placeholder {
    color: #aaa;
  }
  

  .phoneIputMain {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 400px;
    padding: 10px;
    /* padding-left: 28px;
    padding-right: 25px;
    justify-self: center;
    align-self: center; */
}


@media (max-width: 768px){
  .phoneIputMain {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 400px;
    padding-left: 35px;
    margin-left: 10;
    padding-right: 25px;
    justify-self: center;
    align-self: center;
}

}
