.kwn-admin-layout {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.kwn-admin-overflow {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.kwn-left-sidebar-active {
  left: 0px;
  position: absolute;
  z-index: 100;
  transition: all 0.2s;
  /* height: 100%; */
  /* display: flex; */
}

.kwn-left-sidebar-non-active {
  left: -350px;
  position: absolute;
  z-index: 100;
  transition: all 0.2s;
}

@media screen and (max-width: 1050px) {
  .kwn-left-sidebar-active {
    left: -10px;
  }
}
