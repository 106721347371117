.main-container-footer_public {
  width: 100%;
  overflow: hidden;
}

.footerTopView-public {
  background-color: var(--color-theme);
  padding: 3.2rem 29rem;
  -webkit-clip-path: ellipse(70% 45%);
  margin-bottom: -16rem;
  padding-top: 10rem;
  padding-bottom: 20rem;
}

.footerTopView-public h3 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  text-align: center;

  color: #b9b9b9;
}

@media screen and (max-width: 1450px) {
  .footerTopView-public {
    padding: 3.2rem 9.6rem;
    margin-bottom: -16rem;
    padding-top: 13rem;
    padding-bottom: 20rem;
  }
}

@media screen and (max-width: 1050px) {
  .main-container-footer_public {
    width: 100%;
    overflow: visible;
  }
  .footerTopView-public {
    background-color: var(--color-theme);
    padding: 3.2rem 9.6rem;
    -webkit-clip-path: ellipse(100% 100%);
    margin-bottom: 0rem;
    padding-top: 3.2rem;
  }
}

@media screen and (max-width: 480px) {
  .footerTopView-public {
    padding: 3.2rem 3.6rem;
  }
  .footerTopView-public h3 {
    font-size: 1rem;
  }
}
