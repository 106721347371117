.kwn-contact_us-main_container {
  display: flex;
  align-items: center;
  /* padding: 2rem 9.6rem; */
  flex-direction: column;
}

.kwn-contact_us-main_container h1 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 4.8rem;
  line-height: 7.2rem;
  color: #2d292a;
  padding: 8rem 0rem;
  /* align-self: flex-start; */
}

.kwn-contact_us-form_container {
  display: flex;
  flex-direction: column;
  width: 75%;
}

.kwn-contact_us-Button_container {
  margin-top: -2rem;
  margin-bottom: 6rem;
  display: flex;
  justify-content: center;
  margin-top: 2.3rem;
}

.kwn-contact_us-form_sub-container1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

@media screen and (max-width: 480px) {
  .kwn-contact_us-main_container h1 {
    font-size: 2.8rem;
    line-height: 2.6rem;
  }
}
