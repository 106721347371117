


.mainDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    justify-self: center;
    width: 600px;
    
    padding-top: 10px;
    padding-bottom: 10px;
    /* border-color: white; */
    /* background-color: white; */
    /* padding: 20px; */
    border-radius: 10px;

}

.toggleButtonMain{
    margin-top: 20px;
    width: 500px;
}

.headingText {
    font-size: 40px;
    margin-top: 30px;
    font-family: 'Satoshi, sans-serif';
    color: #120D30;
}

.progressBarTextContainr {
    width: 45vw;
}

.subHeadingText {
    font-size: 18px;
    margin-top: 10px;
    font-family: 'Satoshi, sans-serif';
    color: #120D30;
}

.inputButtonDiv {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    gap: 30px;
}

.belowMainDiv {
    margin-top: 40px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.otherMehtodText {
    font-family: 'Satoshi, sans-serif';
    color: #120D30;
    font-size: 15px;
}

.lastDiv {
    margin-top: 70px;
    justify-content: center;
    align-items: center;
}

.lastDivText {
    font-family: 'Satoshi, sans-serif';
    color: #9E9CAA;
    font-size: 17px;
}

.lastDivText2 {
    font-family: 'Satoshi, sans-serif';
    color: #9E9CAA;
    font-size: 17px;
    align-self: center;
}


.phoneInputTitleText{
    font-size: 15px;
    font-family: 'Satoshi', sans-serif;
    color: #120D30;

}

.responsive-input {
    height: 60px;
    width: 500px;
    font-size: 16px;
    background-color: transparent;
    border: 2px solid rgb(7, 7, 7);
    border-radius: 10px;
  }

  .inputStyles {
    flex: 1;
    flex-direction: column;
    width: 400px;
    /* background-color: green; */
    display: flex;
    
  }
  
  






@media (max-width: 768px) {
    .progressBarTextContainr {
        width: 300px;
    }
    
    .singin-buttons-container {
        display: flex;
        flex-direction: column !important;
        gap: 10px !important
    }
    .button_container {
        width: 80vw !important;
    }
    .mainDiv {
        padding-left: 30;
        padding-right: 30;
        width: 500px;
    }
    .headingText {
        font-size: 25px;
        margin-top: 55px;
        font-family: 'Satoshi, sans-serif';
        color: #120D30;
    }
    .subHeadingText {
        font-size: 13px;
        margin-top: 10px;
        font-family: 'Satoshi, sans-serif';
        color: #120D30;
    }
    .lastDivText {
        font-family: 'Satoshi, sans-serif';
        color: #9E9CAA;
        font-size: 15px;
    }
    
    .lastDivText2 {
        font-family: 'Satoshi, sans-serif';
        color: #9E9CAA;
        font-size: 15px;
        align-self: center;
    }
    .inputStyles {
        width: 350px;
       
        
      }
      .toggleButtonMain{
        width: 300px;
      }
    
}

@media (max-width: 600px) {
    .inputStyles {
        width: 300px;
    }
    .mainDiv{
        width: 350px;
    }
  }