.blog-main-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-style {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
}

.blog-main-container {
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 0 15rem;
}

.blog-main-title h1 {
  font-size: 3.6rem;
  font-weight: 500;
  color: #000000;
  font-family: "inter";
  margin-top: 3rem;
  text-align: center;
}
.blog-main-title h3 {
  font-size: 1.5rem;
  font-weight: 400;
  color: #000000;
  font-family: "inter";
  font-style: italic;
  text-align: center;
}

.blog-post-title-container h2 {
  font-size: 2.7rem;
  font-weight: 400;
  color: #000000;
  font-family: "inter";
  margin-top: 5rem;
  margin-bottom: 1rem;
}

.blog-main-picture-container {
  display: flex;
  align-items: center;
  width: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.blog-main-picture-container img {
  width: 100%;
  height: 40rem;
  object-fit: cover;
  margin-top: 4.5rem;
}

.read-blog-main-picture-container img {
  width: 100%;
  height: 40rem;
  object-fit: cover;
  /* margin-top: 4.5rem; */
}

.blog-post-image {
  width: 36rem;
  height: 26rem;
  object-fit: cover;
  /* margin-right: 2rem; */
}

.blog-post-car-container {
  /* margin: 0 3rem; */
  cursor: pointer;
}

.blog-post-car-container h2 {
  font-size: 1.8rem;
  font-weight: 300;
  color: #000000;
  font-family: "inter";
  margin-top: 3.8rem;
}

.blog-slider-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.react-multi-carousel-item {
  margin-right: 14px;
}

.custom-arrow-right img {
  width: 2.2rem;
  height: 2.2rem;
  background-color: red;
}

.post-carousal-main-container {
  margin: 0 0rem;
}
/* -----------------------------Blog - detail------------------- */

.blog-post-detail-time {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* margin-top: 3rem; */
}
.blog-post-detail-social-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
}

.blog-main-category-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-author-tittle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-author-tittle p {
  font-size: 1.3rem;
  font-weight: 600;
  color: #243a54;
  font-family: "inter";
  margin-top: 2rem;
}

.blog-main-category-title p {
  font-size: 1.3rem;
  font-weight: 600;
  color: red;
  font-family: "inter";
  margin-top: 3rem;
}
.blog-post-icon-container {
  width: 4rem;
  height: 4rem;
  border-radius: 2.5rem;
  border: #000000 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 1rem;
}

.blog-post-icon-container img {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: contain;
}
.blog-post-detail-time p {
  font-size: 1rem;
  font-weight: 600;
  color: #243a54;
  font-family: "inter";
  margin-top: 0.8rem;
}
.innerHtml {
  font-family: var(--font-family);
}

.innerHtml h1 {
  font-size: 3.4rem;
}

.innerHtml h2 {
  font-size: 3rem;
}
.innerHtml h3 {
  font-size: 2.6rem;
}
.innerHtml h4 {
  font-size: 2.2rem;
}
.innerHtml h5 {
  font-size: 1.8rem;
}
.innerHtml h6 {
  font-size: 1.6rem;
}

.innerHtml img {
  width: 100%;
  height: 40rem;
  object-fit: cover;
  margin-top: 2rem;
}

.innerHtml p,
li {
  font-size: 2rem;
}

@media screen and (max-width: 1920px) {
  .blog-main-container {
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 0 35rem;
  }
}

@media screen and (max-width: 1765px) {
  .blog-main-container {
    margin: 0 25rem;
  }
}
@media screen and (max-width: 1665px) {
  .blog-main-container {
    margin: 0 20rem;
  }
}
@media screen and (max-width: 1525px) {
  .blog-main-container {
    margin: 0 15rem;
  }
  .blog-post-image {
    width: 33rem;
    height: 21rem;
  }
}

@media screen and (max-width: 1500px) {
  .blog-post-image {
    width: 30rem;
    height: 20rem;
  }
}

/* @media screen and (max-width: 1465px) {
    .blog-post-image {
      width: 25rem;
      height: 17rem;
    }
  } */
@media screen and (max-width: 1265px) {
  .blog-post-image {
    width: 25rem;
    height: 17rem;
  }
}

@media screen and (max-width: 850px) {
  /* .blog-main-title {
    margin-top: 20px;
  } */

  .blog-author-tittle {
    margin-bottom: 10px;
  }

  .blog-main-container {
    margin: 0 5rem;
  }

  .blog-main-picture-container img {
    height: 30rem;
  }

  .innerHtml {
    margin: 0 !important;
  }
}

@media screen and (max-width: 650px) {
  .blog-main-title h1 {
    font-size: 2.6rem;
  }
  /* .blog-main-title {
    margin-top: 20px;
  } */

  .blog-author-tittle {
    margin-bottom: 1rem;
  }

  .blog-main-container {
    margin: 0 5rem;
  }

  .blog-post-detail-time p {
    margin-top: 0rem;
  }

  /* .read-blog-main-picture-container img {
    height: 40rem;
    width: 40rem;
  } */
  /* img {
    height: 40rem;
    width: 40rem;
  } */

  .blog-main-picture-container-points {
    margin-left: 2rem !important;
  }

  .innerHtml {
    margin: 0 !important;
  }

  .innerHtml img {
    height: 40rem !important;
    width: 40rem !important;
  }
}

@media screen and (max-width: 550px) {
  .read-blog-main-picture-container img {
    height: 100%;
    width: 100%;
  }
  .innerHtml img {
    height: 100% !important;
    width: 100% !important;
  }
  .innerHtml h2 {
    line-height: 2.7rem !important;
  }
}

@media screen and (max-width: 440px) {
  .blog-post-image {
    width: 20rem;
    height: 10rem;
  }
  .react-multi-carousel-item {
    margin-right: 0px;
  }

  .blog-main-picture-container img {
    height: 25rem;
  }
  .blog-main-container {
    margin: 0 2.5rem;
  }
  .innerHtml p,
  li {
    font-size: 1.8rem;
  }
  /* .read-blog-main-picture-container {
    height: 25rem;
    width: 25rem !important;
  } */
}
