.sidebar {
    width: 380px;
    background-color: #fff;
    border-right: 1px solid lightgray;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding-right: 13px;
}


.drawer-toggle-button {
    position: relative;
    margin-right:2%;
    height:30px;
    width: 30px;
    align-items: center;
    justify-content: center;
    background-color: #022D2E;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    z-index: 1001;
    display: none; /* Hide by default, show on mobile */
  }







.sidebar-search {
    display: flex;
    align-items: center;

    padding: 6px;
    margin-right: 6px;
    margin-bottom: 12px;
    border-radius: 10px;
    background-color: #F6F6F6;

    padding-right: 10px;
    width:fit-content;
    position: fixed;
}

.search-icon {
    width: 20px;
    /* Adjust size as needed */
    height: 20px;
    /* Adjust size as needed */
    margin-left: 6px;
    margin-right: 6px;
}

.sidebar-search input {
    flex: 1;
    font-family: var(--font-family);
    padding: 6px;
    border: none;
    outline: none;
    background-color: #F6F6F6;
}

.sidebar-search input::placeholder {
    color: #92929D;
}

.sidebar-chat {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 15px;

}

.sidebar-chat:hover {
    background-color: #ebebeb;
}

.sidebar-chat-image {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.sidebar-chat-info {
    margin-left: 10px;
}

.sidebar-chat-info h4 {
    margin: 0;
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 500;
}

.sidebar-chat-info p {

    margin-top: 8px;
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 400;
    color: gray;
}