.bg {
  background: #120d30;
  color: #ffffff;
  /* padding: 60px; */
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
  border: "2px solid #120d30";
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.bg > div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  /* max-width: 1400px; */
}

.left {
  position: absolute;
  top: 21%;
  /* transform: translateY(-50%); */
  left: 60px;
}

.logo {
  width: 150px;
  height: auto;
}

.right {
  /* flex: 1; */
  display: flex;
  /* justify-content: space-between; */
  gap: 80px;
  margin-left: 300px;
  margin-right: 90px;
}

.right > div {
  display: flex;
  flex-direction: column;
}

.right h1 {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.right a {
  font-family: var(--font-family);
  font-size: 14px;
  margin: 5px 0;
  cursor: pointer;
  transition: color 0.3s ease;
}
.download {
  display: flex;
  flex-direction: column;
  gap: 10;
}

.downloadLogo {
  width: 120px;
  padding-top: 10px;
  height: auto;
}

.hr {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  border: 0.5px solid #ffffff;
  margin-top: 30px;
}
.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  padding-top: 30px;
}

.footerText {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  margin-right: 800px;
}

.footer-icons {
  display: flex;
  gap: 15px;
}

.footer-icons img {
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.footer-icons img:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .footer-bottom {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 15px;
  }

  .footer-icons {
    justify-content: center;
  }
}
