.blog-kawan-title {
  width: 15rem;
  height: 3.7rem;
}
.kavan__navbar-white {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* align-items: center; */
}
.horizontal-line-blog {
  width: 86%;
  height: 1px;
  background-color: #3d5a80;
  margin: 0 7%;
}

@media screen and (max-width: 560px) {
  .horizontal-line-blog {
    width: 93.8% !important;
  }
  .horizontal-line-blog {
    margin: 0 3%;
  }
}
