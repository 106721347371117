.mainApp {
  display: flex;
  justify-content: center;
  /* height: 100vh; */
  align-items: center;
  overflow-y: auto;
  /* or overflow-y: scroll; */
  background-color: #E6EFEB;
  padding-top: 70px;
  font-family: Satoshi;
}

.App {
  display: flex;
  flex-direction: column;
  width: 400px;
  background-color: #E6EFEB;
}

.fieldsInput {
  padding: 12px 24px 12px 24px;
  /* width: 100%; */
  width: 400px;
  border-radius: 16px;
  margin-bottom: 20px;
  background-color: transparent;
  box-shadow: 0px 0.2px #120D30;
  border: 1px solid #120D30;
}

.fieldsInput::placeholder {
  color: #AAADB5;
  font-size: 11px;
  font-family: Satoshi;
  font-weight: 400;
}

.topText {
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  align-self: center;
  margin: 30px 30px;
  /* line-height: 25.2px ; */
}

.form-fields {
  margin-top: 1rem;
}

.labels {
  font-size: 11px;
  color: #120D30;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 10px;
}


.custom-checkbox {
  width: 20px;
  height: 20px;
  border: 1px solid #2b6139;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
}

.custom-checkbox-filled {
  width: 20px;
  height: 20px;
  background-color: #2b6139;
  border: 1px solid #2b6139;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
}


.checkboxRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkboxValue {
  padding: 20px;
}

.middleText {
  display: flex;
  align-items: center;
  flex-direction: row;
  align-self: center;
  column-gap: 10px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;

}

.middleTextStyle {
  text-wrap: nowrap;
  color: #8B8F9A;
  font-weight: 700;
  font-size: 14px;
}

.leftLine {
  width: 60%;
  height: 1px;
  background-color: #8B8F9A;
}

.rightLine {
  width: 60%;
  height: 1px;
  background-color: #8B8F9A;
}

.socialImage {
  height: 20px;
  width: 20px;
}

.googleButton {
  background-color: white;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  box-shadow: 10px;
  font-weight: 700;
  opacity: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  /* width: 100%; */
  width: 400px;
  padding: 12px 71px 12px 71px;
  border-radius: 20px;
  align-items: center;
  display: flex;
  column-gap: 15px;
  border: none;
  justify-content: center;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.17), 0px 0px 3px 0px rgba(0, 0, 0, 0.08);
}

.appleButton {
  border: none;
  background-color: black;
  box-shadow: 10px;
  opacity: 1px;
  color: #FFFFFF;
  margin-top: 10px;
  margin-bottom: 10px;
  /* width: 100%; */
  width: 400px;
  padding: 12px 71px 12px 71px;
  border-radius: 20px;
  align-items: center;
  cursor: pointer;
  display: flex;
  column-gap: 10px;
  justify-content: center;
  margin-bottom: 30px;
}

.termsName {
  color: black;
  font-size: 11px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 8px;
}

.submitButton {
  background-color: #056038;
  color: #E6EFEB;
  margin-top: .7rem;
  margin-bottom: .7rem;
  /* width: 100%; */
  width: 400px;
  padding: 12px 71px 12px 71px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 18px;
  border-width: 0px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.App-logo {
  height: 46px;
  width: 190px;
  align-self: center;
  margin-right: 20px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.checkbox input {
  position: absolute;
  opacity: 0;
}

.checkbox .overlay {
  position: relative;
  top: 0px;
  right: 0px;
  left: 20px;
  height: 24px;
  width: 24px;

  background-color: transparent;
  border-radius: 8px;
  border: 2px solid #F39C12;
  transform: rotate(-90deg);
  transition: all 0.3s;
}

.checkbox .icon {
  color: white;
  display: none;
}

.checkbox input:checked~.overlay {
  background-color: #F39C12;
  border-radius: 8px;
}

.passwordInputContainer {
  /* position: relative; */
}

.fieldsInput {
  padding: 12px 24px 12px 24px;
  width: 400px;
  border-radius: 16px;
  margin-bottom: 20px;
  background-color: transparent;
  box-shadow: 0px 0.2px #120D30;
  border: 1px solid #120D30;
}

.passwordVisibilityToggle {
  /* position: absolute;
  top: 35%;
  right: 50px;
  transform: translateY(-50%); */
  cursor: pointer;
  width: 20px;
  height: 20px;
  color: #AAADB5;
  margin-left: -30px;
  margin-bottom: -5px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 360px) {
  .App {
    display: flex;
    flex-direction: column;
    width: 343px;
    background-color: #E6EFEB;
    overflow: hidden;
    margin-top: -20px;
  }

  .fieldsInput {
    padding: 12px 24px 12px 24px;
    width: 315px !important;
    margin-left: 15px;
    border-radius: 16px;
    margin-bottom: 20px;
    background-color: transparent;
    box-shadow: 0px 0.2px #120D30;
    border: 1px solid #120D30;
  }

  .labels {
    font-size: 11px;
    margin-left: 15px;
    color: #120D30;
    font-weight: 700;
    /* width: 346px; */
    font-style: normal;
    margin-bottom: 10px;
  }

  .checkboxRow {
    display: flex;
    flex-direction: row;
    margin-left: 15px;
    align-items: center;
  }

  .submitButton {
    background-color: #056038;
    color: #E6EFEB;
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
    margin-left: 15px;
    /* width: 100%; */
    width: 315px !important;
    padding: 12px 71px 12px 71px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 18px;
    border-width: 0px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .middleText {
    display: flex;
    align-items: center;
    flex-direction: row;
    align-self: center;
    column-gap: 10px;
    width: 315px !important;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .socialImage {
    height: 20px;
    margin-right: -5px;
    width: 20px;
  }

  .googleButton {
    background-color: white;
    color: rgba(0, 0, 0, 0.54);
    cursor: pointer;
    box-shadow: 10px;
    font-weight: 700;
    opacity: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
    /* width: 100%; */
    width: 315px !important;
    padding: 12px 71px 12px 71px;
    border-radius: 20px;
    align-items: center;
    display: flex;
    column-gap: 15px;
    margin-left: 15px;
    border: none;
    justify-content: center;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.17), 0px 0px 3px 0px rgba(0, 0, 0, 0.08);
  }

  .appleButton {
    border: none;
    background-color: black;
    box-shadow: 10px;
    opacity: 1px;
    color: #FFFFFF;
    margin-top: 10px;
    margin-bottom: 10px;
    /* width: 100%; */
    width: 315px !important;
    padding: 12px 71px 12px 71px;
    border-radius: 20px;
    align-items: center;
    cursor: pointer;
    margin-left: 15px;
    display: flex;
    column-gap: 10px;
    justify-content: center;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 418px) {
  .App {
    display: flex;
    flex-direction: column;
    width: 370px;
    background-color: #E6EFEB;
    overflow: hidden;
    margin-top: -20px;
  }

  .fieldsInput {
    padding: 12px 24px 12px 24px;
    width: 370px;
    /* margin-left: 15px; */
    border-radius: 16px;
    margin-bottom: 20px;
    background-color: transparent;
    box-shadow: 0px 0.2px #120D30;
    border: 1px solid #120D30;
  }

  .labels {
    font-size: 11px;
    margin-left: 15px;
    color: #120D30;
    font-weight: 700;
    /* width: 346px; */
    font-style: normal;
    margin-bottom: 10px;
  }

  .checkboxRow {
    display: flex;
    flex-direction: row;
    /* margin-left: 15px; */
    align-items: center;
  }

  .submitButton {
    background-color: #056038;
    color: #E6EFEB;
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
    /* margin-left: 15px; */
    /* width: 100%; */
    width: 370px;
    padding: 12px 71px 12px 71px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 18px;
    border-width: 0px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .middleText {
    display: flex;
    align-items: center;
    flex-direction: row;
    align-self: center;
    column-gap: 10px;
    width: 370px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .socialImage {
    height: 20px;
    margin-right: -5px;
    width: 20px;
  }

  .googleButton {
    background-color: white;
    color: rgba(0, 0, 0, 0.54);
    cursor: pointer;
    box-shadow: 10px;
    font-weight: 700;
    opacity: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
    /* width: 100%; */
    width: 370px;
    padding: 12px 71px 12px 71px;
    border-radius: 20px;
    align-items: center;
    display: flex;
    column-gap: 15px;
    /* margin-left: 15px; */
    border: none;
    justify-content: center;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.17), 0px 0px 3px 0px rgba(0, 0, 0, 0.08);
  }

  .appleButton {
    border: none;
    background-color: black;
    box-shadow: 10px;
    opacity: 1px;
    color: #FFFFFF;
    margin-top: 10px;
    margin-bottom: 10px;
    /* width: 100%; */
    width: 370px;
    padding: 12px 71px 12px 71px;
    border-radius: 20px;
    align-items: center;
    cursor: pointer;
    /* margin-left: 15px; */
    display: flex;
    column-gap: 10px;
    justify-content: center;
    margin-bottom: 30px;
  }
}