.kwn-profile-text_inputs_top_view {
    margin-top: 12.6rem;
    margin-bottom: 3rem;

}

.kwn_edit_profile_button_view {
    margin-bottom: 5rem;
    align-self: flex-end;

}