.kavan_admin_main_container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex: 1;
  overflow-y: scroll;
  scroll-behavior: smooth;
  padding: 0rem 5rem;
}

.border-pending {
  border-left-color: #D2AF26 !important;
  border-left-width: 10px !important;
}

.kavan_admin_main_container {
  /* overflow: -moz-scrollbars-none; */
}

.kavan_admin_main_container {
  /* -ms-overflow-style: none; */
}

#low-text {
  width: 22rem !important;
}

.kwn_appointments_type_top_view {
  display: flex;
  flex-direction: row;
  margin-right: -5rem;
  overflow-x: scroll;
  overflow-y: hidden;
}

.kwn_appointments_type_top_view::-webkit-scrollbar {
  width: 0 !important;
}

.kwn_appointments_type_top_view {
  overflow: -moz-scrollbars-none;
}

.kwn_appointments_type_top_view {
  -ms-overflow-style: none;
}

.kwn_appointments_type_view {
  padding: 1.5rem 3rem;
  /* width: 14.4rem; */
  /* height: 5rem; */
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  margin-top: 3rem;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 3rem;
  cursor: pointer;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  margin-bottom: 1rem;
}

.kwn_appointments_type_view h3 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 2rem;
  line-height: 2rem;
  letter-spacing: -0.5px;
  text-transform: capitalize;
}

.kwn-appointsments-appointment_view {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  /* height: 30%; */
  /* min-height: 60%; */
  padding-bottom: 5rem;
}

.kwn-upcoming-appointment-calender-tool_tip-main_container {
  display: flex;
  flex-direction: row;

  width: 40rem;
  /* height: 18rem; */
}

.kwn-side_divider-calender {
  width: 0.5rem;
  height: 16rem;
  margin: 2rem 0rem;
  background: #006039;
  border-radius: 10px;
}

.kwn-appointment-calender-tool_tip-container {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}

.kwn-appointment-up_coming-tool_tip-title h1 {
  font-size: 1.6rem;
  line-height: 19px;
  font-family: var(--font-family);
}

.kwn-appointment-calender-tool_tip-date-main_container {
  display: flex;
  flex-direction: row;
  width: 32rem;
  align-items: center;
  margin: 1rem 0 2rem 0;
}

.kwn-appointment-calender-tool_tip-date-main_container img {
  width: 2rem;
  height: 2rem;
}

.kwn-appointment-calender-tool_tip-date-container h3 {
  margin-left: 3rem;
  font-size: 1.4rem;
  font-weight: 400;
  font-family: var(--font-family);
  color: #666666;
}

.dx-scheduler-header .dx-scheduler-header-panel .dx-dropdowneditor .dx-dropdowneditor-button .dx-dropdowneditor-icon {
  font-size: 10px !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-size: 2.2rem !important;
}

.css-1rit21i-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 1.2rem !important;
  color: rgba(0, 98, 61, 1) !important;
}

.css-xya8j6-MuiButtonBase-root-MuiButton-root.TodayButton-button {
  color: rgba(0, 98, 61, 1) !important;
  font-size: 2.2rem !important;
  border: 1px solid rgba(0, 98, 61, 1) !important;
}

.css-1675apn-MuiTableCell-root .Cell-highlightedText {
  color: rgba(0, 98, 61, 1) !important;
}

.css-i4bv87-MuiSvgIcon-root {
  width: 3rem !important;
  height: 3rem !important;
}

.css-vnscjq-MuiButtonBase-root-MuiButton-root.OpenButton-textButton {
  font-size: 2.6rem !important;
  color: rgba(0, 98, 61, 1) !important;
}

.css-71a9mb-MuiButtonBase-root-MuiIconButton-root.NavigationButton-button {
  color: rgba(0, 98, 61, 1) !important;
}

.css-1675apn-MuiTableCell-root .Cell-dayOfWeek {
  font-size: 2.2rem !important;
}

.css-1675apn-MuiTableCell-root .Cell-highlightedText {
  font-size: 2.6rem !important;
}

.css-1fbp63r .Label-text {
  font-size: 2rem !important;
}

.css-gyiown .VerticalAppointment-title {
  font-size: 1.4rem !important;
}

.css-gyiown .VerticalAppointment-textContainer {
  font-size: 1rem !important;
}

.css-1b98pvr-MuiTypography-root {
  font-size: 2rem !important;
}

.css-yyuo3s-MuiTableCell-root {
  font-size: 1.3rem !important;
}

.css-19yoqom-MuiTableCell-root {
  font-size: 1.2rem;
}

.css-1fklddj-MuiModal-root-MuiPopover-root .Layout-popover {
  width: 30rem !important;
}

.css-1wwby0q .Layout-popover {
  width: 30rem !important;
}

.css-8ludsl.Header-head {
  min-height: 0px !important;
}

/* --------------------------------------------- */

.css-1kf53p2 {
  font-size: 2rem !important;
  color: rgba(0, 98, 61, 1) !important;
}

.css-mjjfsp {
  color: rgba(0, 98, 61, 1) !important;
}

.css-136w3t4 {
  font-size: 1.4rem !important;
  color: rgba(0, 98, 61, 1) !important;
  border: 1px solid rgba(0, 98, 61, 1) !important;
}

.css-qiwgdb {
  font-size: 1.2rem !important;
  color: rgba(0, 98, 61, 1) !important;
  border: 0.5px solid rgba(0, 98, 61, 1) !important;
}

.css-1km1ehz {
  font-size: 1.4rem !important;
  color: rgba(0, 98, 61, 1) !important;
}

.css-1m1e8dc .Cell-highlightedText {
  font-size: 2.2rem !important;
  color: rgba(0, 98, 61, 1) !important;
}

.css-1m1e8dc .Cell-dayOfWeek {
  font-size: 1.4rem !important;
  color: rgba(0, 98, 61, 1) !important;
}

.kawan-appointment-content {
  width: 100%;
  height: 110%;
  background-color: rgba(0, 98, 61, 1);
  border-radius: 10px;
  border: 1px #fff solid;
}

.kawan-appointment-content-date-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: center; */
}

.kawan-appointment-content-date-container h2 {
  font-weight: 400;
  font-size: 12px;
  font-family: var(--font-family);
  color: #fff;
  margin-left: 1.5rem;
  margin-top: 0.5rem;
}

.kawan-appointment-content-date-container p {
  font-weight: 400;
  font-size: 10px;
  font-family: var(--font-family);
  color: #fff;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

.kwn-appointment_content-container {
  /* height: 8.8rem; */
  max-width: 30rem;
  background: #fff;
  display: flex;
  align-items: center;
  flex-direction: row;
  /* justify-content: center; */
  box-shadow: 4px 4px 15px rgba(57, 61, 72, 0.1);
  border-radius: 12px;
}

.kwn-appointment-content-slider {
  width: 5px;
  height: 6.2rem;
  background-color: #006039;
  border-radius: 10px;
}

.kwn-appointment-content {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  /* width: 36.8rem; */
}

.kwn-appointment-content-time-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  /* max-width: 35rem; */
}

.kwn-appointment-content-time-sub-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.kwn-appointment-content-time-sub-container img {
  width: 15px;
  height: 15px;
}

.kwn-appointment-content-time-sub-container h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-family: var(--font-family);
  color: #666666;
  margin-left: 0.3rem;
}

.kwn-appointment-content-divider {
  border: 1px solid #dadada;
  margin-bottom: 6px;
  width: 80%;
}

.kwn-appointment-content-three-dot {
  margin-bottom: 10px;
}

.kwn-appointment-content-three-dot img {
  width: 1rem;
  height: 2rem;
  object-fit: contain;
  cursor: pointer;
}

.kwn-appointment-content-btn-main-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 0.5rem;
}

.kwn-appointment-content-profile_name {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.kwn-appointment-content-profile_name img {
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 1.4rem;
}

.kwn-appointment-content-profile_name-sub-container h2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  font-family: var(--font-family);
  color: #2d292a;
  margin-left: 1rem;
}

.kwn-appointment-content-profile_name-sub-container h3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-family: var(--font-family);
  color: #666666;
  margin-left: 1rem;
  white-space: nowrap;
}

.dashboard-btn-main-container {
  /* margin-left: 1.5rem; */
}

.green-btn {
  /* margin-left: 1rem; */
  /* margin-top: 0.5rem; */
}

.css-3nejl.Appointment-appointment {
  background-color: #006039 !important;
}

.appointment-color-blue .css-3nejl.Appointment-appointment {
  background-color: blue !important;
}

.scheduler-container {
  border: 1px solid rgb(224, 224, 224);
  border-radius: 10px;
}

.scheduler-mainbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(224, 224, 224);
  margin-right: 1rem;
}

.scheduler-topbar {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 9rem;
  padding-left: 0rem;
  position: relative;
}

.date-navigator {
  cursor: pointer;

}

.scheduler-topbar>.date-navigator>img {
  width: 20px;
}

.scheduler-topbar>.date-navigator.left>img {
  transform: scaleX(-1);
  margin-right: 2rem;
}

.arrowRight {
  border: none !important;
  transform: scaleX(-1);
  height: 22px;

}

.arrowLeft {
  border: none !important;
  height: 22px;
}

.scheduler-topbar>h1 {
  margin-left: 3rem;
  font-family: Roboto;
  color: rgb(0, 98, 61);
  padding: 1rem;
  cursor: pointer;
}

.scheduler-topbar>h1:hover {
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  background-color: rgba(0, 98, 61, 0.1);
  color: white;
}

.view-button {
  border: 3px solid #006039;
  margin-left: 1rem;
  border-radius: 5px;
  height: 40px;
  width: 80px;
  font-family: Roboto;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(0, 98, 61);
  cursor: pointer;
}

.view-button:hover {
  background-color: rgba(0, 98, 61, 0.1);
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
}

.today-button {
  border: 2px solid #686b6a;
  margin-left: 1rem;
  border-radius: 8px;
  padding-left: 5px;
  padding-right: 5px;
  height: 40px;
  /* width: 80px; */
  font-family: Roboto;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(0, 98, 61);
  cursor: pointer;
}

#arrowLeft,
#arrowRight {
  border: none;
}

.scheduler-topbar h1 {
  color: rgb(0, 98, 61);
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.scheduler-topbar h1:hover {
  color: rgb(0, 98, 61);
  cursor: pointer;
  background-color: rgb(0, 98, 61, 0.2);
  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}

.today-button:hover {
  background-color: rgb(0, 98, 61);
  color: white;
  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}

.scheduler-content-container {
  display: flex;
  /* padding-bottom: 15rem; */
}

.scheduler-sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 9rem;
  border-right: 1px solid rgb(224, 224, 224);
}

.scheduler-sidebar>.sidebar-header {
  height: 7rem !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(224, 224, 224);
}

.sidebar-header-week {
  height: 9rem !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(224, 224, 224);
}

.scheduler-sidebar>.sidebar-header-week>h3 {
  font-family: Roboto;
  font-weight: 100;
  font-size: 16px;
}

.scheduler-sidebar>.sidebar-header>h3 {
  font-family: Roboto;
  font-weight: 100;
  font-size: 16px;
}

.scheduler-content {
  width: 100%;
}

.scheduler-content-section {
  width: 100%;
  height: 7rem !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid rgb(224, 224, 224);
}

.scheduler-content-section:hover {
  background-color: rgb(0, 0, 0, 0.1);
}

.calendar-main {
  position: absolute;
  top: 55px;
  left: 115px;
}

.spaced-content {
  flex-direction: column;
  justify-content: space-evenly;
  height: 15rem;
}

.spaced-content>* {
  height: 40% !important;
}

.scheduler-content-item {
  color: white;
  display: flex;
  flex-direction: column;
}

.scheduler-content-premium {
  background-color: #D2AF26;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 20%;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: white;
  font-family: Inter;
}

.kwn-comp-small-btn {
  margin-right: 3px;
}

.scheduler-content-item-card {
  height: 90%;
  display: flex;
  justify-content: space-between;
  width: 45%;
  /* padding-left: 1rem; */
  border-radius: 3px;
  cursor: pointer;
}

.scheduler-content-item>.scheduler-content-item-time {
  margin-top: 1rem;
  margin-bottom: 3px;
}

.scheduler-content-item>p {
  font-family: Inter;
  font-size: 9px;
}


.scheduler-content-item-tooltip-container {
  z-index: 100;
}

.weekly-scheduler-content-container {
  display: flex;
}

.weekly-scheduler-days {
  display: flex;
}

.weekly-scheduler-days-item {
  height: 9rem;
  width: 14.28%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(224, 224, 224);
  border-right: 1px solid rgb(224, 224, 224);
}

.weekly-scheduler-days-item>h2 {
  font-family: Roboto;
  font-weight: 100;
  font-size: 18px;
  cursor: pointer;
  font-size: 25px;
}

.innerid {
  zoom: 1.35;
}

.weekly-scheduler-days-item>p {
  font-family: Roboto;
  font-weight: 100;
  font-size: 19px;
  cursor: pointer;
}

.weekly-scheduler-hours {
  width: 100%;
}

.weekly-scheduler-line {
  display: flex;
  width: 100%;
}

.weekly-scheduler-complete-content {
  width: 100%;
}

.weekly-schedule-block {
  display: flex;
  width: 14.28%;
  height: 9rem;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgb(224, 224, 224);
  border-bottom: 1px solid rgb(224, 224, 224);
}


.weekly-schedule-block:hover {
  background-color: rgb(0, 0, 0, 0.1);
}

.weekly-schedule-appointment-block-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.weekly-schedule-appointment-block {
  height: 26px;
  width: 40%;
  background-color: rgb(0, 98, 61);
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 1rem;
}

.weekly-schedule-appointment-block>h2 {
  font-size: 10px;
  margin-left: 5px;
}

.scale-low {
  /* transform: scale(0.4) !important;
  margin-left: -4vw !important; */
  /* margin-bottom: -9vh !important; */
}

.small-appointment-container {
  background: white;
  box-shadow: 4px 4px 15px rgba(57, 61, 72, 0.1);
  padding: 2px;
  margin-top: .3rem;
  border-left: solid 2px #006039;
  border-radius: 5px;
  width: 130px;
}

.small-details-button-container {
  margin-top: 0rem;
}

.small-title-container {
  display: flex;
  align-items: center;
}

.small-title-container>img {
  height: 7px;
}

.small-title-container>p {
  height: 8px;
  font-size: 8px;
  margin-left: 5px;
}

.small-border-divider-container {
  display: flex;
  justify-content: center;
}

.small-border-divider {
  background: lightgrey;
  margin-top: 2px;
  margin-bottom: 1px;
  width: 95%;
  height: 1px;
}

.small-details-container {
  /* margin-top: 5px; */
  display: flex;
}

.small-details-container>img {
  height: 10px;
  width: 10px;
  margin-right: 5px;
}

.small-details-container>p {}

.small-green-button>.kwn-comp-small-btn {
  margin-top: unset !important;
}

.shadow-container {
  -webkit-box-shadow: -8px 17px 52px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -8px 17px 52px -15px rgba(0, 0, 0, 0.75);
  box-shadow: -8px 17px 52px -15px rgba(0, 0, 0, 0.75);
  border-radius: 25px;
}

/* -------Media Query------ */
@media screen and (max-width: 1450px) {
  .css-1fbp63r .Label-text {
    font-size: 1.4rem !important;
  }

  .css-1675apn-MuiTableCell-root .Cell-dayOfWeek {
    font-size: 1.6rem !important;
  }

  .css-1675apn-MuiTableCell-root .Cell-highlightedText {
    font-size: 1.6rem !important;
  }

  .css-vnscjq-MuiButtonBase-root-MuiButton-root.OpenButton-textButton {
    font-size: 1.6rem !important;
  }

  .css-xya8j6-MuiButtonBase-root-MuiButton-root.TodayButton-button {
    font-size: 1.6rem !important;
  }

  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-size: 1.6rem !important;
  }

  .css-i4bv87-MuiSvgIcon-root {
    width: 2rem !important;
    height: 2rem !important;
  }

  .css-1b98pvr-MuiTypography-root {
    font-size: 1.8rem !important;
  }
}

@media screen and (max-width: 500px) {
  .kwn-appointment-calender-tool_tip-date-container h3 {
    width: 20rem;
    margin-left: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .kavan_admin_main_container {
    padding: 0rem 2rem;
  }

  .kwn_appointments_type_view {
    margin-top: 2rem;
    margin-right: 1rem;
  }

  .kwn_appointments_type_top_view {
    width: 100%;
  }
}

@media screen and (max-width: 420px) {
  .kwn-appointsments-appointment_view {
    align-items: center;
  }

  .scheduler-container {
    width: 95%;
  }

  .scheduler-topbar {
    height: 11rem;
  }

  .kwn-appointment-content-divider {
    width: 97%;
  }

  .scheduler-sidebar>.sidebar-header-week {
    height: 11rem;
  }

  .scheduler-sidebar>.sidebar-header {
    height: 11rem;
  }

  .kwn-appointment_content-container {
    height: 10rem;
  }

  .kwn-appointment-content-time-sub-container h4 {
    font-size: 1.4rem;
  }

  .dashboard-btn-main-container {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    width: 14rem !important;
    margin-bottom: .2rem;
  }

  .green-btn {
    margin-left: 0;
  }

  .kavan_admin_main_container {
    padding: 0rem 2rem !important;
    overflow-x: scroll;
  }

  .scheduler-topbar>h1 {
    margin-left: 1rem;
  }

  .scheduler-topbar {
    padding-left: 2rem;
  }
}

.dx-scheduler-header-panel .dx-scheduler-header-panel-cell {
  font-size: 48px !important;
}

.dx-scheduler-date-table-cell {
  font-size: 48px !important;
}

.red-appointment {
  background-color: red !important;
}

.weekly-schedule {
  flex: 2;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow-y: auto;
  padding-bottom: 10vh;
}

/* Style the table */
.weekly-schedule table {
  width: 100%;
  border-collapse: collapse;
}

.user-bio {
  resize: none;
  overflow: hidden;
  min-height: 50px;
  max-height: 100px;
}

.weekly-schedule th,
.weekly-schedule td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.weekly-schedule th {
  background-color: #f2f2f2;
}

.edit-icon {
  position: absolute;
  z-index: 1;
  align-self: flex-end;
  cursor: pointer;
  height: 22px;
  width: 22px;
  right: 10px;
  top: 10px;
}

.edit-text {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  align-self: flex-end;
  color: #006039;
  text-decoration: underline;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 1.4rem;
  right: 10px;
  top: 10px;
}

.edit-btn-position-container {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  z-index: 5;
  right: 3rem;
  bottom: 0rem;
}

.bio-text {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 20px;
}

.bio-label {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.8rem;
}

.user-name-2 {
  font-family: var(--font-family);
  font-size: 2.8rem;
}

/* Style the input fields */
.weekly-schedule input[type="time"] {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}