.chat-header {
    background-color: #fff;
    font-family: var(--font-family);
    color: black;
    padding: 10px;
    display: flex;
    align-items: center;

}

.chat-header-image {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.chat-header-info {
    display: flex;
    align-items: center;
    margin-left: 10px;
    flex: 2;

}

.chat-header-info h3 {
    margin: 0;
    color: #818181;
    font-weight: 600;
}

.chat-agenda-button {
    border: 2px solid #000000;
    margin-left: 14px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 100px;

}


.chat-header-info p {
    margin: 0;
    font-size: 14px;
    color: #449368;
    font-weight: 600;
}