.main-video {
    width: 70%;
    margin: auto;
    text-align: center;
}

.main-video #join-flow button {
    margin-top: 20px;
    background-color: #2D8CFF;
    color: #ffffff;
    text-decoration: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 40px;
    display: inline-block;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    outline: none;
}

.main-video #join-flow button:hover {
    background-color: #2681F2;
}

.Video {
    background: #d4e4dd;
}

@media screen and (max-width: 500px) {
    #participant-canvas {
        height: 70vh !important;
    }    
}