body {
    background-color: #e6efeb;
}

.chatbot-container {
    margin-top: 80;
    background-color: #e6efeb;
    display: flex;
    justify-content: center;
    align-items: center;

    /* position: fixed;
    padding: 0;
    margin: 0;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%; */
}

.chatbot-container-scrollable {
    
    background-color: #e6efeb;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 130vh;
}

.chatbot-start-main {
    /* width: 70rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
}

.chatbot-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    width: 450px;
}

.chatbot-headingg1 {
    display: flex;
    justify-content: center;
    align-items: center;
    /* justify-content: space-between; */
    width: 450px;
}

.chatbot-headingg1>img {
    width: 70px;
    height: 50px;
    margin-right: 1rem;
}



.chatbot-heading>img {
    width: 70px;
    height: 50px;
    margin-right: 1rem;
}
.chatbot-headingg1>h1 {
    font-size: 24.25px;
    letter-spacing: 5px;
    font-family: 'Satoshi';
    font-weight: 400;
}

.chatbot-heading>h1 {
    font-size: 24.25px;
    letter-spacing: 5px;
    font-family: 'Satoshi';
    font-weight: 400;
}

#chatbot-heading {
    width: 45vw;
    display: flex;
    justify-content: space-between;
}

#chatbot-heading>h1 {
    font-size: 32px;
    font-family: 'Satoshi';
    font-weight: bold;
    color: #120D30;
}

.chatbot-text-main-conversation-container {
    width: 50vw;
    height: 50vh;
    margin-top: 70px;
    overflow-y: auto;
    /* background-color: #04494B; */
}

.typing {
    width: 5em;
    height: 2em;
    position: relative;
    padding: 10px;
    margin-left: 5px;
    background: #e6e6e6;
    border-radius: 20px;
    display: flex;
}

.typing__dot {
    float: left;
    width: 8px;
    height: 8px;
    margin: 1px;
    margin-top: -3px;
    background: #8d8c91;
    border-radius: 50%;
    opacity: 0;
    animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
    animation-delay: 0s;
}

.typing__dot:nth-child(2) {
    animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
    animation-delay: 0.4s;
}


.skipBot {
    color: #9C9C9C;
    font-size: 15px;
    margin-top: 20px;
    margin-left: 10px;
    font-family: 'Satoshi', sans-serif;
    cursor: pointer;
}




@keyframes loadingFade {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.8;
    }

    100% {
        opacity: 0;
    }
}

.chatbot-text-main-conversation-container::-webkit-scrollbar {
    width: 1px;
}

.chatbot-text-main-conversation-container::-webkit-scrollbar-track {
    /* background: #666666; */
    border-radius: 2px;
}

.chatbot-text-main-conversation-container::-webkit-scrollbar-thumb {
    background: rgba(255, 0, 0, 0);
    border-radius: 10px;
    /* width: 10px; */
}

.message-option-texts {
    display: flex;
    justify-content: space-around;
    width: 50vw;
    overflow-x: auto;
}

.message-option-texts::-webkit-scrollbar {
    width: 1px;
    height: 5px;
}

.message-option-texts::-webkit-scrollbar-track {
    /* background: #666666; */
    border-radius: 2px;
    height: 5px;
}

.message-option-texts::-webkit-scrollbar-thumb {
    background: rgba(255, 0, 0, 0);
    border-radius: 10px;
    height: 5px;
    /* width: 10px; */
}

.message-option-texts::-webkit-scrollbar-thumb {
    background: #666666;
    height: 5px;
}

.chatbot-text-main-conversation-container::-webkit-scrollbar-thumb:hover {
    background: #666666;
    height: 5px;
}

.message-text-option {
    /* margin-top: 15px; */
    display: flex;
    justify-content: center;
    /* align-items: center; */
    width: 110px;
    border: 1px solid #120D3033;
    /* margin-bottom: 15px; */
    border-radius: 10px;
    white-space: nowrap;
    /* margin-right: 35px; */
    height: 45px;
    /* padding: 10px; */
    background-color: white;
}

.mainUpperDiv{
    display: flex;
    flex-direction: row;
}


.optionsMainDiv{
    display: flex;
    max-width: 400;
    flex-wrap: wrap;
    gap: 10px;
    margin-left: 20px;
}


.message-text-option p {
    font-family: Satoshi;
    border-radius: 5px;
    cursor: pointer;
    font-size: 13px;
}

.bottom-messages {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.bottom-messages img {
    /* margin-left: 20px; */
    height: 40px;
    cursor: pointer;
}

.converstation-snippet {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    /* flex-direction: column; */
    /* width: 100%; */
    /* background-color: #04494B; */
}

.imageBackgroundDiv{
    
    background-color: #5F5F5F;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    height: 50px;
    width: 50px;
    margin-left: 10px;
}

.converstation-snippet img {
    height: 40px;
    /* width: 40px; */
}

.converstation-snippet p {
    margin-left: 10px;
    margin-top: 12px;
    max-width: 530px;
    color: #120D30;
    font-family: 'Satoshi';
    font-size: 15px;
}

.bottom-messages input {
    padding-left: 10px;
    width: 43vw;
    height: 6vh;
    outline: none;
    border: none;
    border-radius: 10px;
    background-color: #F7F7FD;
}

.chatbot-desc {
    margin-top: 3rem;
}

.chatbot-desc>p {
    width: 785px;
    height: 50px;
    font-family: Satoshi;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
}

.chatbot-main-list-container {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
}

.chatbot-main-list-item {
    display: flex;
    margin-bottom: 3rem;
    width: 70rem;
}

.chatbot-main-list-item>img {
    width: 50px;
    height: 50px;
    gap: 10px;

}

.chatbot-main-list-item p {
    margin-top: .8rem;
    margin-left: 1.5rem;
    font-family: Satoshi;
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;

    color: #120D30;
}

.chatbot-main-button {
    width: 190px;
    height: 57px;
    left: 625px;
    border-radius: 20px;
    color: white;
    background-color: #04494B;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Satoshi;
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    cursor: pointer;
}

.chatbot-bottom-text-container {
    margin-top: 2rem;
    display: flex;
    font-family: Satoshi;
    font-size: 15px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
}

.chatbot-bottom-text {
    margin-right: .5rem;
}

.chatbot-link {
    color: #056038;
    cursor: pointer;
}

.chatbot-main-question-container {
    margin-top: 3rem;
    /* height: 55vh; */
    overflow-y: hidden;
}

.chatbot-main-question {
    font-family: Satoshi;
    /* height: 60vh; */
}

.chatbot-main-question-q {
    font-family: Satoshi;
    font-size: 20px;
    width: 785px;
}

.chatbot-main-question-q>p {
    font-family: Satoshi;
}

.chatbot-main-question {
    font-family: Satoshi;
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.chatbot-main-question-answer>p {
    width: 208px;
}

.chatbot-main-question-answers {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    min-width: 45vw;
    max-height: 35vh;
    flex-wrap: wrap;
    overflow-x: hidden;
    /* align-items: center; */
    /* justify-content: center; */
}

#chatbot-question-2>.chatbot-main-question-answers>.chatbot-main-question-answer {
    margin-bottom: 1rem;
}

#chatbot-question-4>.chatbot-main-question-answers>.chatbot-main-question-answer {
    width: unset;
    margin-bottom: 1rem;
}

#chatbot-question-5>.chatbot-main-question-answers {
    margin-top: 2rem;
}

#chatbot-question-5>.chatbot-main-question-answers>.chatbot-main-question-answer {
    font-size: 16px;
    margin-bottom: 1rem;
}

#chatbot-question-2>.chatbot-main-question-answers>.chatbot-main-question-answer>img {
    margin-right: 1rem;
}

#chatbot-question-4>.chatbot-main-question-answers>.chatbot-main-question-answer>img {
    margin-right: 1rem;
}

#chatbot-question-5>.chatbot-main-question-answers>.chatbot-main-question-answer>img {
    margin-right: 1rem;
}

/* #chatbot-question-5 > .chatbot-main-question-answers > .chatbot-main-question-answer > p {
    max-width: 100px;
} */

.q-starter {
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.chatbot-main-question-answers-starter {
    display: flex;
    justify-content: center;
    align-items: center;
    height: unset !important;
}

.chatbot-main-question-answers-starter>input {
    background-color: #e6efeb;
    border-radius: 15px;
    border-width: 2px;
    border-color: black;
    width: 70%;
    height: 5rem;
    text-indent: 15px;
}

.chatbot-main-question-answer {
    display: flex;
    align-items: center;
    margin-left: 5rem;

    font-family: Satoshi;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;

    margin-bottom: 3rem;
}

.chatbot-main-question-answer>img {
    width: 24px;
    height: 24px;
    margin-right: 3rem;
}

.progress-chatbot-container {
    height: 6px;
    border-radius: 100px;
    background: #F9FAFA;
}

.progress-chat-main {
    height: 6px;
    border-radius: 100px;
    background: #99BEAD;
}


.chatbot-progress-text {
    margin-top: 1rem;

    font-family: Satoshi;
    font-size: 14px;
    font-weight: 200;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 1rem;
}

.progress-section-container {
    margin-top: 5rem;
    width: 785px;
}

.chatbot-main-list-container {}

.chatbot-doctor-card {

    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0px;

    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    margin-bottom: 2rem;
    border-radius: 12px;
    padding: 1rem;

    width: 305px;
}

.card-top-part {
    display: flex;
}

.card-top-part>img {
    height: 5rem;
    width: 5rem;
    border-radius: 8px;
}

.card-desc-part {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}

.card-desc-part>h1 {
    font-size: 16px;
    color: #2D292A;
    font-family: Roboto;
    margin-bottom: .5rem;
}

.card-desc-part>h2 {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
}

.card-desc-part>p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
}

.card-bottom-part {
    margin-top: 1rem;
    display: flex;
    justify-content: space-around;
}

.card-bottom-part>p {
    font-family: Roboto;
    font-size: 10px;
    width: 90px;
    height: 30px;
    border-radius: 60px;
    border: solid 1px #2D292A;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    margin: 0.3rem;
    line-height: 11px;
}

.rating-container {
    display: flex;
    align-items: center;

    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
}

.rating-container>img {
    height: 20px;
    width: 20px;
    margin-right: 1rem;
}

.progressBarText {
    color: #9C9C9C;
    font-size: 15px;
    margin-top: 20px;
    margin-left: 10px;
    font-family: 'Satoshi', sans-serif;
    cursor: pointer;
    width: 450px
}



/* ================= Media Queries =================== */
@media screen and (max-width: 700px) {
    #chatbot-heading {
        width: 300px;
    }
}

@media screen and (max-width: 360px) {
    #chatbot-heading {
        width: 300px;
    }
    
    .chatbot-heading {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        /* margin-top: -50px; */
        /* height: 39.64px; */
        width: unset;
    }

    .skipBot {
        color: #9C9C9C;
        font-size: 12px;
        margin-top: 20px;
        margin-left: 10px;
        font-family: 'Satoshi', sans-serif;
        cursor: pointer;
    }

    .progressBarText {
        color: #9C9C9C;
        font-size: 12px;
        margin-top: 20px;
        margin-left: 10px;
        font-family: 'Satoshi', sans-serif;
        cursor: pointer;
        width: unset;
    }

    .progress-chatbot-container {
        margin-top: 0;
        width: 330px !important;
    }

    .chatbot-desc>p {
        width: 343px;
        height: unset;
        margin-bottom: 10px;
        font-family: Satoshi;
        font-size: 18px;
        font-weight: 700;
        line-height: 22.4px;
        letter-spacing: 0rem;
        text-align: center;
    }

    .chatbot-main-list-container {
        margin-top: 10rem;
        font-size: 16px;
        font-style: normal;
        width: 310px;
        display: flex;
        flex-direction: column;
    }

    .chatbot-main-list-item p {
        margin-top: 0.8rem;
        margin-left: 1.5rem;
        font-family: Satoshi;
        width: 268px;
        font-size: 13px !important;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #120D30;
    }

    .chatbot-main-button {
        width: 343px !important;
        height: 57px;
        left: 625px;
        border-radius: 20px;
        color: white;
        background-color: #056038;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Satoshi;
        padding: 16px 71px;
        font-size: 16px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        cursor: pointer;
    }

    .chatbot-bottom-text-container {
        margin-top: 2rem;
        display: flex;
        font-family: Satoshi;
        font-size: 11px;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
    }

    .progress-section-container {
        margin-top: 0rem;
        width: 343px !important;
    }

    .chatbot-main-question-answers-starter>input {
        background-color: #e6efeb;
        border-radius: 15px;
        border-width: 2px;
        border-color: black;
        width: 343px !important;
        height: 5rem;
        text-indent: 15px;
    }

    .q-starter {
        font-size: 13px !important;
    }

    .chatbot-main-list-item {
        display: flex;
        /* width: 70rem; */
        width: 340px;
        margin-left: -15px;
        /* background-color: #2D292A; */
    }

    .chatbot-main-question-container {
        margin-top: 8rem;
        margin-bottom: -5rem;
        /* height: 55vh; */
        overflow-y: hidden;
    }

    .chatbot-container {
        margin-top: 4rem;
    }

    .chatbot-start-main {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        overflow-y: scroll;
        align-items: center;
        margin-top: unset;
        overflow-x: hidden;
        /* margin-top: 218px; */
    }

    #chatbot-start-main-id {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        overflow-y: scroll;
        align-items: center;
        margin-top: 50px;
    }

    .chatbot-main-question-q {
        font-family: Satoshi;
        font-size: 18px;
        width: 343px;
    }

    .chatbot-main-question-answers {
        display: flex;
        /* background-color: olive; */
        margin-top: 2rem;
        margin-bottom: 35px;
        flex-direction: row;
        min-width: 45vw;
        max-height: 100vh;
        flex-wrap: wrap;
        margin-left: -35px;
        overflow-x: hidden;
    }

    .chatbot-main-question-answers2 {
        margin-top: 2rem;
        display: flex;
        margin-bottom: -15px;
        flex-direction: column;
        min-width: 45vw;
        max-height: 35vh;
        flex-wrap: wrap;
        overflow-x: hidden;
    }

    /* #chatbot-question-1 > .chatbot-main-question-answers > .chatbot-main-question-answer {
        margin-left: -10rem;
    } */

    .chatbot-doctor-card {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 3rem !important;
        line-height: 19px;
        letter-spacing: 0px;
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
        margin-bottom: 2rem;
        border-radius: 12px;
        padding: 1rem;
        width: 305px;
    }

    .chatbot-container {
        background-color: #e6efeb;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
        top: 0;
        left: 0;
        /* width: 100%; */
        /* height: 100vh; */
        overflow-y: scroll;
    }

    #chatbot-question-1>.chatbot-main-question-answers>.chatbot-main-question-answer {
        width: unset;
        margin-bottom: 2rem;
    }

    #chatbot-question-2>.chatbot-main-question-answers>.chatbot-main-question-answer {
        width: unset;
        margin-bottom: 2rem;
    }

    #chatbot-question-4>.chatbot-main-question-answers>.chatbot-main-question-answer {
        width: 340px;
        margin-bottom: 2rem;
    }

    #chatbot-question-5>.chatbot-main-question-answers>.chatbot-main-question-answer {
        width: 300px;
        margin-bottom: 2rem;
    }

    #chatbot-question-6>.chatbot-main-question-answers>.chatbot-main-question-answer {
        margin-bottom: 1.5rem;
    }

    #chatbot-question-6>.chatbot-main-question-answers>.chatbot-main-question-answer>p {
        margin-left: -10px;
    }

    #chatbot-question-7>.chatbot-main-question-answers>.chatbot-main-question-answer {
        margin-bottom: 1.5rem;
    }

    #chatbot-question-7>.chatbot-main-question-answers>.chatbot-main-question-answer>p {
        margin-left: -10px;
    }

    #chatbot-question-8>.chatbot-main-question-answers>.chatbot-main-question-answer {
        margin-bottom: 1.5rem;
    }

    #chatbot-question-9>.chatbot-main-question-answers>.chatbot-main-question-answer {
        margin-bottom: 1.5rem;
    }

    #chatbot-question-10>.chatbot-main-question-answers>.chatbot-main-question-answer {
        margin-bottom: 1.5rem;
    }

    #chatbot-question-11>.chatbot-main-question-answers>.chatbot-main-question-answer {
        margin-bottom: 1.5rem;
    }
}

@media screen and (max-width: 418px) {
    .chatbot-heading {
        width: 105px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        /* margin-top: -50px; */
        /* height: 39.64px; */
        width: unset;
    }

    .chatbot-desc>p {
        width: 343px;
        height: unset;
        margin-bottom: 10px;
        font-family: Satoshi;
        font-size: 18px;
        font-weight: 700;
        line-height: 22.4px;
        letter-spacing: 0rem;
        text-align: center;
    }

    .chatbot-main-list-container {
        margin-top: 10rem;
        font-size: 16px;
        font-style: normal;
        width: 310px;
        display: flex;
        flex-direction: column;
    }

    .chatbot-main-list-item p {
        margin-top: 0.8rem;
        margin-left: 1.5rem;
        font-family: Satoshi;
        width: 268px;
        font-size: 13px !important;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #120D30;
    }

    .chatbot-main-button {
        width: 370px;
        height: 57px;
        left: 625px;
        border-radius: 20px;
        color: white;
        background-color: #056038;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Satoshi;
        padding: 16px 71px;
        font-size: 16px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        cursor: pointer;
    }

    .chatbot-bottom-text-container {
        margin-top: 2rem;
        display: flex;
        font-family: Satoshi;
        font-size: 11px;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
    }

    .progress-section-container {
        margin-top: 0rem;
        width: 370px;
    }

    .chatbot-main-question-answers-starter>input {
        background-color: #e6efeb;
        border-radius: 15px;
        border-width: 2px;
        border-color: black;
        width: 370px;
        height: 5rem;
        text-indent: 15px;
    }

    .q-starter {
        font-size: 13px !important;
    }

    .chatbot-main-list-item {
        display: flex;
        /* width: 70rem; */
        width: 340px;
        margin-left: -15px;
        /* background-color: #2D292A; */
    }

    .chatbot-main-question-container {
        margin-top: 8rem;
        margin-bottom: -5rem;
        /* height: 55vh; */
        overflow-y: hidden;
    }

    .chatbot-container {
        margin-top: 4rem;
    }

    .chatbot-start-main {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        overflow-y: scroll;
        align-items: center;
        margin-top: 60px;
    }

    #chatbot-start-main-id {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        overflow-y: scroll;
        align-items: center;
        margin-top: 50px;
    }

    .chatbot-main-question-q {
        font-family: Satoshi;
        font-size: 18px;
        width: 343px;
    }

    .chatbot-main-question-answers {
        display: flex;
        /* background-color: olive; */
        margin-top: 2rem;
        margin-bottom: 35px;
        flex-direction: row;
        min-width: 45vw;
        max-height: 100vh;
        flex-wrap: wrap;
        margin-left: -30px;
        overflow-x: hidden;
    }

    .chatbot-main-question-answers2 {
        margin-top: 2rem;
        display: flex;
        margin-bottom: -15px;
        flex-direction: column;
        min-width: 45vw;
        max-height: 35vh;
        flex-wrap: wrap;
        overflow-x: hidden;
    }

    /* #chatbot-question-1 > .chatbot-main-question-answers > .chatbot-main-question-answer {
        margin-left: -10rem;
    } */

    .chatbot-doctor-card {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 3rem !important;
        line-height: 19px;
        letter-spacing: 0px;
        height: 150px;
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
        margin-bottom: 2rem;
        border-radius: 12px;
        padding: 1rem;
        margin-left: -35px;
        width: 370px;
    }

    .chatbot-container {
        background-color: #e6efeb;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
        top: 0;
        left: 0;
        /* width: 100%; */
        /* height: 100vh; */
        overflow-y: scroll;
    }

    .card-bottom-part>p {
        font-family: Roboto;
        font-size: 11px;
        width: 90px;
        height: 30px;
        border-radius: 60px;
        border: solid 1px #2D292A;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        margin: 0.3rem;
        line-height: 11px;
    }

    #chatbot-question-1>.chatbot-main-question-answers>.chatbot-main-question-answer {
        width: unset;
        margin-bottom: 2rem;
    }

    #chatbot-question-2>.chatbot-main-question-answers>.chatbot-main-question-answer {
        width: unset;
        margin-bottom: 2rem;
    }

    #chatbot-question-4>.chatbot-main-question-answers>.chatbot-main-question-answer {
        width: 340px;
        margin-bottom: 2rem;
    }

    #chatbot-question-5>.chatbot-main-question-answers>.chatbot-main-question-answer {
        width: 300px;
        margin-bottom: 2rem;
    }

    #chatbot-question-6>.chatbot-main-question-answers>.chatbot-main-question-answer {
        margin-bottom: 1.5rem;
    }

    #chatbot-question-6>.chatbot-main-question-answers>.chatbot-main-question-answer>p {
        margin-left: -10px;
    }

    #chatbot-question-7>.chatbot-main-question-answers>.chatbot-main-question-answer {
        margin-bottom: 1.5rem;
    }

    #chatbot-question-7>.chatbot-main-question-answers>.chatbot-main-question-answer>p {
        margin-left: -10px;
    }

    #chatbot-question-8>.chatbot-main-question-answers>.chatbot-main-question-answer {
        margin-bottom: 1.5rem;
    }

    #chatbot-question-9>.chatbot-main-question-answers>.chatbot-main-question-answer {
        margin-bottom: 1.5rem;
    }

    #chatbot-question-10>.chatbot-main-question-answers>.chatbot-main-question-answer {
        margin-bottom: 1.5rem;
    }

    #chatbot-question-11>.chatbot-main-question-answers>.chatbot-main-question-answer {
        margin-bottom: 1.5rem;
    }
}


@media (max-width: 768px) {
    .chatbot-container {
        margin: 0 10px;
        display: flex;
        flex: 1;
        width: 100%;
        justify-self: center;
        align-self: center;
    }

    .chatbot-start-main {
        margin-top: 10rem;
        padding: 15px;
        margin-top: unset !important;
    }

    /* .chatbot-container {
        overflow-y: clip !important;
        height: 99vh;
    } */

    .chatbot-heading h1 {
        font-size: 24px; /* Adjust heading size for smaller screens */
    }

    .chatbot-desc p {
        font-size: 14px; /* Adjust text size */
    }

    .chatbot-main-list-container {
        margin-top: 2rem;
    }

    .chatbot-main-list-item {
        margin-bottom: 2rem;
        /* flex-direction: column; */
        text-align: center;
    }

    .chatbot-main-list-item img {
        margin: 0 auto 10px; /* Center the icon */
        width: 30px; /* Smaller icon for mobile */
        height: 30px;
    }

    .chatbot-main-button {
        width: 100%;
        font-size: 14px; /* Smaller font for mobile */
    }

    .chatbot-bottom-text-container {
        /* flex-direction: column; */
        text-align: center;
    }

    .chatbot-bottom-text {
        margin: 0 0 0.5rem 0;
    }

    .chatbot-link {
        font-size: 14px;
    }
}
