.kwn-sign_up-main_container {
  display: flex;
  align-items: center;
  padding-top: 8rem;
  padding-left: 9.6rem;
  padding-right: 9.6rem;
  padding-bottom: 10rem;
  flex-direction: column;
}

.kwn-sign_up-title_container {
  margin-bottom: 6rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 45rem;
}

.kwn-sign_up-title_container h1 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 3.2rem;
  line-height: 4.9rem;
  color: #2d292a;
  text-align: center;
}

.kwn-sign_up-title_container h2 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.3rem;
  color: #2d292a;
  margin-top: 1rem;
}

.kwn-sign_up-form_container {
  display: flex;
  flex-direction: column;
}

.kwn-sign_up-terms_container {
  width: 40rem;
  display: flex;
  align-items: center;
  padding: 1.5rem 1.5rem;
}

.kwn-sign_up-terms_container img {
  width: 2rem;
  height: 2rem;
  margin-right: 1.5rem;
}

.kwn-sign_up-terms_container p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #2d292a;
}

.kwn-sign_up-terms_container span {
  text-decoration: underline;
}

.kwn-sign_up-Button_container {
  margin-left: 0.5rem;
}

.kwn-sign_up-main_container h3 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 300;
  font-size: 2.6rem;
  line-height: 3.6rem;
  color: #2d292a;
  padding-top: 5rem;
}

.kwn-sign_up-social_container {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
  width: 43rem;
  margin-top: 5rem;
}

.kwn-sign_up-social_container div {
  display: flex;
  align-items: center;
  border: 0.1rem solid #666666;
  border-radius: 5rem;
  width: 19.5rem;
  padding: 0.8rem 0rem;
  justify-content: space-evenly;
  margin-bottom: 2.5rem;
}

.kwn-sign_up-social_container p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #2d292a;
}

.kwn-sign_up-social_container img {
  width: 3rem;
  height: 3rem;
}

@media screen and (max-width: 480px) {
  .kwn-sign_up-title_container {
    width: 33rem;
  }

  .kwn-sign_up-main_container {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .kwn-sign_up-social_container {
    width: 20rem;
  }
  .kwn-sign_up-terms_container {
    width: 30rem;
  }
  .kwn-sign_up-title_container h2 {
    margin-top: 0;
  }
}

@media screen and (max-width: 350px) {
  .kwn-sign_up-main_container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
