.otpmainApp {
    display: flex;
    justify-content: center;
    height: 100vh;
    /* align-items: center; */
    background-color: #E6EFEB;
    padding-top: 8rem;
    font-family: Satoshi;
}

.otpApp {
    display: flex;
    flex-direction: column;
    width: 25vw;
    /* background-color: #056038; */
}

.App-logo-Otp {
    height: 46px;
    width: 160px;
    align-self: center;
    margin-right: 20px;
    margin-bottom: 30px;
}

.Verificationtext {
    font-size: 18px;
    font-weight: 700;
    align-self: center;
    margin-bottom: 2rem;
    margin-top: .5rem;
}

.textDecription {
    align-self: center;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 1rem;
}

.otpView {
    align-self: center;
    margin-bottom: 20px;
    margin-top: 25px;
    margin-left: 10px;
}

.round {
    border-radius: 16px;
    height: 50px !important;
    width: 50px !important;
    background-color: #E6EFEB;
    border: 1px solid #120D30;
}

/* .round :active {
    border: 1px solid #120D30;

} */

.btn {
    align-self: center;
    width: 150px;
    height: 45px;
    margin: 0 20px;
    padding: 10px;
    padding: 10px;
    background-color: #056038;
    border-width: 1px;
    border-color: #056038;
    border-radius: 20px;
    white-space: nowrap;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: none;
    cursor: pointer;
    margin-top: 1rem;
}

.textStyle {
    text-align: center;
    color: white;

}


@media screen and (max-width: 360px) {
    .Verificationtext {
        font-size: 18px !important;
        font-weight: 700;
        width: 343px !important;
        display: flex;
        justify-content: center;
        align-self: center;
        margin-bottom: 2rem;
        margin-top: 0.5rem;
    }

    .App-logo-Otp {
        height: 46px;
        width: 160px;
        align-self: center;
        margin-right: 20px;
        margin-bottom: 75px;
    }

    .btn {
        align-self: center;
        width: 150px;
        height: 45px;
        margin: 0 20px;
        padding: 10px;
        padding: 10px;
        background-color: #056038;
        border-width: 1px;
        border-color: #056038;
        border-radius: 20px;
        white-space: nowrap;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        border: none;
        cursor: pointer;
        margin-top: 2rem;
    }

    .textDecription {
        font-size: 12px !important;
        font-weight: 400;
        margin-bottom: 2rem;
        margin-top: 10px;
        width: 343px !important;
        display: flex;
        justify-content: center;
        align-self: center;
    }
}


@media screen and (max-width: 418px) {
    .Verificationtext {
        font-size: 20px;
        font-weight: 700;
        width: 370px;
        justify-content: center;
        display: flex;
        align-self: center;
        margin-bottom: 2rem;
        margin-top: 0.5rem;
    }

    .App-logo-Otp {
        height: 46px;
        width: 160px;
        align-self: center;
        margin-right: 20px;
        margin-bottom: 75px;
    }

    .btn {
        align-self: center;
        width: 150px;
        height: 45px;
        margin: 0 20px;
        padding: 10px;
        padding: 10px;
        background-color: #056038;
        border-width: 1px;
        border-color: #056038;
        border-radius: 20px;
        white-space: nowrap;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        border: none;
        cursor: pointer;
        margin-top: 2rem;
    }

    .textDecription {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 2rem;
        margin-top: 10px;
        width: 343px;
        display: flex;
        justify-content: center;
        align-self: center;
    }
}