.profile-section-header {
    border-radius: 10px;
    justify-content: space-between;
    font-family: var(--font-family);
    color: black;
    padding: 10px;

    display: flex;
    align-items: center;
    position: relative;

}

.profile-section-container {
    background-color: #fff;
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.1);
    padding: 1rem;
}

.input-container-with-label {
    display: flex;
    align-items: center;
}

.input-container-with-label > h3 {
    font-size: 15px;
    color: #449368;
    font-weight: 600;
    font-family: var(--font-family);
    margin-right: 1vw;
    white-space: nowrap;
}

.left-section-profile {
    width: 80vw;
}

.profile-screen {
    background-color: #fff;

    padding-bottom: 10vh;
    padding-left: 15%;
    padding-right: 15%;

}

.profile-section-row {
    display: flex;
    margin-top: 12px;
    justify-content: space-between;
}

.profile-header-image {
    border-radius: 50%;
    width: 55px;
    height: 55px;
}

.edit-icon-image {
    width: 15px;
    height: 15px;
    right: 0;
    bottom: 0;
    position: absolute;
}

.price-input {
    border-color: #98B4B5;
    border-radius: 8px;
    margin-left: 16px;
    font-family: var(--font-family);
    color: #449368;
    font-size: 14px;
    padding: 10px;
    display: flex;
    align-items: center;
}

.input-box {
    border: 1px solid #98B4B5;
    border-radius: 8px;
    font-family: var(--font-family);
    color: "#3A3653";
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    padding: 10px;
    align-items: center;
    margin-bottom: 14px;
    margin-top: 6px;
}

.user-info-text {
    font-size: 14px;
    color: #3A3653;
    font-weight: 500;
    margin-top: 10px;
    font-family: var(--font-family);
}


.price-label {
    font-size: 14px;
    color: #449368;
    font-weight: 600;
    font-family: var(--font-family);
    margin-right: 1vw;
}

.input-label {
    font-size: 20px;
    margin-bottom: 12px;
    color: #04494B;
    font-weight: 600;
    font-family: var(--font-family);
}

.input-edit-label {
    font-size: 14px;
    margin-left: 8px;
    text-decoration-line: underline;
    color: #006039;
    font-weight: 700;
    font-family: var(--font-family);

    cursor: pointer;


}

.profile-section-label {
    font-size: 20px;
    color: #04494B;
    font-weight: 600;
    font-family: var(--font-family);
}

.profile-section-label2 {
    margin-top: 18px;
    font-size: 16px;
    color: #04494B;
    font-weight: 400;
    font-family: var(--font-family);
}

.input-container {
    background-color: #fff;
    margin-top: 12px;
    border-radius: 10px;
    font-family: var(--font-family);
    padding: 10px;
    align-items: center;
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.1);
    position: relative;
}



.mobile-view {
    width: 70vw;
    background-color: #ECF3F0;
    margin-left: 10%;
    font-family: var(--font-family);
    padding: 6px;
    border-radius: 12px;
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.1);
}

.user-mobile-image {
    height: 230px;
    margin-top: 8px;
    overflow: hidden;
    object-fit: cover;
    border-radius: 12px;
    align-self: center;
    width: 340px;

}

.user-mobile-name {
    margin-top: 12px;
    margin-left: 6px;
    font-size: 18px;
    color: #000000;
    font-weight: 500;

}


.user-mobile-gender {
    margin-top: 2px;
    margin-left: 6px;
    font-size: 14px;
    color: #3A3653;
    font-weight: 500;
}

.mobile-review-box {
    display: flex;
    align-items: center;
    margin-top: 4px;
}


.user-mobile-box {
    margin-top: 10px;
    border: 1.5px solid #98B4B5;
    border-radius: 8px;
    padding: 8px;
}

.normal-text-mobile {
    font-size: 13px;
    color: #3A3653;
    font-weight: 500;
    margin-left: 8px;
}

.mobile-box-heading {
    font-size: 15px;
    color: #120D30;
    font-weight: 600;
    margin-left: 8px;
}