.kavan-select-specialty-container {
  width: 44.4rem;
  height: 10.4rem;
  border-radius: 5.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 3rem;
  cursor: pointer;
}

.kavan-select-specialty-container h3 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 26px;
  line-height: 3.2rem;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.kavan-select-specialty-container p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.7rem;
}

@media screen and (max-width: 480px) {
  .kavan-select-specialty-container {
    width: 34.4rem;
    height: 8.4rem;
  }
}
