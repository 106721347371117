.kwn-questions_top_view {
    display: flex;
    flex-direction: column;
    margin-top: 2.5rem;
}

.kwn-questions_view {
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 5rem;
    padding: 2rem 3rem;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.kwn-questions_view h2 {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.3rem;
    color: #666666;
}

.kwn-questions_view_img {
    width: 3.2rem;
    height: 3.2rem;
    cursor: pointer;
}

.kwn-questions_view_img_open {
    width: 3.2rem;
    height: 3.2rem;
    cursor: pointer;
    transform: rotate(90deg);
}

.kwn-questions_answers_view {
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 1.5rem;
    padding: 2rem 3rem;
    align-items: center;
    display: flex;
    margin-bottom: 2rem;
}

.kwn-questions_answers_view h2 {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.3rem;
    color: #666666;
}