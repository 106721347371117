/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Mende+Kikakui&family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&family=Noto+Sans+Mende+Kikakui&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;800;900&display=swap");

@font-face {
  font-family: Satoshi;
  src: url("./assets/Satoshi.ttf");
}

:root {
  --font-family: "Satoshi";
  --font-family-manrope: "Manrope", sans-serif;
  --font-family-poppins: "Poppins", sans-serif;

  --gradient-text: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%);

  --color-white: #ffffff;
  --color-theme: #006039;
}

html {
  font-size: 62.5%;
}

* {
  box-sizing: border-box;
}