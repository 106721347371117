.chat-messages {
    flex: 1;
    margin-left: 8px;
    border-radius: 10px;
    background-color: #F6F6F6;
    padding: 20px;
    overflow-y: scroll;
}

.chat-user-image {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
}

.chat-message-text {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 3rem;
}

.chat-message {
    margin-bottom: 10px;
    padding: 10px;
    max-width: 60%;
    position: relative;
}

.chat-bubble {
    display: flex;
}

.chat-message.sent {
    background-color: #006039;
    margin-left: auto;
    color: white;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
    border-top-right-radius: 18px;

}

.chat-message.received {
    background-color: white;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;

}

.chat-timestamp {
    font-size: 12px;
    color: gray;

    font-family: var(--font-family);
}

.chat-timestamp.sent {
    text-align: right;
    display: block;
}

.chat-timestamp.received {
    text-align: left;
    margin-left: 18px;
    display: block;
}