.buttonGroupMainDivv{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-self: center;
    align-self: center;
    
}

.leftButtonStyles{
    flex:0.5;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e0e0e0;
    cursor: pointer;
}


.rightButtonStyles{
    flex:0.5;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e0e0e0;
    cursor: pointer;
}

.greenBackColor{
    background-color:  #04494B;
}



.buttonGroupTexts{
    font-size: 18px;
    font-family: 'Satoshi';
    color: rgb(53, 52, 52);
    
    margin: 0;
    padding: 0;
}

.whiteText{
    color: #ffffff;
}
