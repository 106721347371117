.mianContainer {
  display: flex;
  flex: 1;
  /* padding: 4.8rem 12.8rem; */
  background-color: rgb(255, 255, 255);
  flex-direction: column;
}

.kawan-about_us-main-image-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.kawan-about_us-main-image-container h1 {
  margin-top: 30.4rem;
  font-weight: 400;
  font-size: 7.4rem;
  color: #fff;
  font-family: var(--font-family);
  margin-bottom: 6rem;
}
.kawan-about_us-main-image-container p {
  font-size: 2.4rem;
  color: #fff;
  font-family: var(--font-family);
  font-weight: 300;
  width: 74rem;
  margin-bottom: 4rem;
}

.kawan-about_us-emoji-main-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 12rem;
  margin-bottom: 10rem;
  justify-content: space-around;
  flex-wrap: wrap;
}
.kawan-about_us-emoji-container {
  width: 39rem;
  height: 34rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 28.15px 66.31px rgba(1, 23, 21, 0.09);
  position: relative;
  border-radius: 2rem;

  /* margin-right: 3.2rem; */
}

.kawan-about_us-emoji-sub-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  margin-left: 3rem;
  position: absolute;
  top: -3rem;
}
.kawan-about_us-emoji-circle {
  display: flex;
  align-items: center;
  justify-self: center;
}

.kawan-about_us-emoji-circle {
  width: 14rem;
  height: 14rem;
  border-radius: 7rem;
  background-color: #3d5a80;
  display: flex;
  align-items: center;
  justify-content: center;
}

.kawan-about_us-emoji-sub-container h1 {
  font-size: 2.8rem;
  color: var(--color-theme);
  font-family: var(--font-family);
  margin-left: 2.5rem;
}

.kawan-about_us-emoji-container p {
  font-size: 1.8rem;
  color: var(--color-theme);
  font-family: var(--font-family);
  font-weight: 400;
  color: #000000;
  margin: 14.4rem 2.6rem 3.5rem 3rem;
}

.kawan-about_us-who_is-main-container {
  width: 100%;
  flex-direction: row;
  display: flex;
}
.kawan-about_us-who_is-img {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 20rem;
  margin-bottom: 20rem;
}

.kawan-about_us-who_is-des-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}

.kawan-about_us-who_is-des-h1 h1 {
  font-size: 4.5rem;
  color: var(--color-theme);
  font-family: var(--font-family);
  font-weight: 500;
  margin-top: 5.9rem;
}
.kawan-about_us-who_is-des-container p {
  font-size: 1.8rem;
  color: #000000;
  font-family: var(--font-family);
  font-weight: 400;
  width: 54rem;
  line-height: 3rem;
}

.kawan-about_us-who_is-list-tag-container {
  display: flex;
  flex-direction: column;
}

.kawan-about_us-list-tag-container {
  display: flex;
  flex-direction: row;
  margin-top: 5rem;
  align-items: center;
}

.kawan-about_us-list-tag-container h1 {
  font-size: 1.8rem;
  font-weight: 400;
  font-family: var(--font-family);
  color: var(--color-theme);
  text-decoration: underline;
  margin-left: 2rem;
  cursor: pointer;
}
.kawan-about_us-who_is-professional-heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8.3rem;
  margin-bottom: 11rem;
}
.kawan-about_us-who_is-professional-heading h3 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 4.5rem;
  color: var(--color-theme);
  align-self: center;
}
.kawan-about_us-who_is-proffes-review-container {
  min-width: 39.3rem;
  /* height: 49rem; */
  border-radius: 2rem;
  border: 1px solid #449368;
  background: #f7fffb;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  margin-right: 2.8rem !important;
  /* box-sizing: border-box; */
}

.kawan-about_us-who_is-prof-profile-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.kawan-about_us-who_is-prof-profile-container img {
  width: 7.4rem;
  height: 7.4rem;
  border-radius: 3.8rem;
}

.kawan-about_us-who_is-prof-profile-name-container {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}

.kawan-about_us-who_is-prof-profile-name-container h2 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 1.8rem;
  color: var(--color-theme);
}

.kawan-about_us-who_is-prof-profile-name-container h3 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.8rem;
  color: #3d5a80;
}
.kawan-about_us-who_is-proffes-review-container p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.8rem;
  color: #000000;
  margin-top: 3rem;
  line-height: 3rem;
}
/* ----Media Query */

@media screen and (max-width: 850px) {
  .kawan-about_us-main-image-container h1 {
    font-size: 4.4rem;
  }
  .kawan-about_us-main-image-container p {
    width: 52rem;
  }
  .kawan-about_us-emoji-container {
    margin-bottom: 6rem;
  }
  .kawan-about_us-who_is-professional-heading h3 {
    font-size: 3rem;
  }

  .kawan-about_us-who_is-proffes-review-container {
    min-width: 29rem;
  }
  .kawan-about_us-who_is-prof-profile-name-container h2 {
    font-size: 1.6rem;
  }
  .kawan-about_us-who_is-prof-profile-name-container h3 {
    font-size: 1.6rem;
  }
  .kawan-about_us-who_is-proffes-review-container p {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .kawan-carousal-btn-for {
    right: 43% !important;
  }

  .kawan-carousal-btn-back {
    right: 43% !important;
  }
}

@media screen and (max-width: 430px) {
  .kawan-about_us-main-image-container p {
    width: 30rem;
  }
}

/* ------------------------------ */
.aboutTextDiv {
  width: 100%;
}

.aboutTextDiv h1 {
  display: flex;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 56px;
  line-height: 72px;
  letter-spacing: -0.639344px;
  color: #2d292a;
  text-align: center;
  justify-content: center;
}

.usersTopDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 6rem;
}

.usersDiv {
  position: relative;
  /* background-color: #006039; */
  flex-wrap: wrap;
  /* display: flex; */
}

.verifiedLogo {
  position: absolute;
  width: 60px;
  height: 60px;
  left: 32rem;
  top: -3rem;
}

.verifiedDiv {
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 22px;
  /* padding: 3rem 3rem; */
  padding-top: 3rem;
  padding-bottom: 3rem;
  /* padding-right: 10rem; */
  padding-left: 3rem;
  width: 34.2rem;
}

.verifiedDiv img {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-right: 1.6rem;
}

.verifiedDiv h1 {
  font-family: var(--font-family-manrope);
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 1.6rem;
  color: #111235;
}

.userDetailView {
  display: flex;
  margin-top: 2.4rem;
  /* margin-bottom: ; */
}

.userDetailView h2 {
  font-family: var(--font-family-manrope);
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;

  color: #111235;
}

.userDetailView p {
  font-family: var(--font-family-manrope);
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;

  color: #6c6c6c;
}
.seeMoreDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 12rem;
  margin-top: 4.8rem;
}
.seeMoreDiv h4 {
  font-family: var(--font-family);

  font-weight: 500;
  font-size: 18px;
  line-height: 27px;

  color: #ff6160;
}

.contactDiv {
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: red;
  /* box-sizing: border-box; */
  position: absolute;
  padding: 2.6rem 0rem;
  width: 37rem;
  left: 19rem;
  bottom: -6.5rem;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 22px;
  flex-direction: column;
}

.contactDiv img {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin-bottom: 1.6rem;
}

.contactDiv h2 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 0.8rem;
  color: #111235;
}

.contactDiv p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #6c6c6c;
}

.chooseView {
  display: flex;
  flex-direction: column;
  /* margin-top: 2rem; */
  width: 59rem;
}

.chooseView h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  text-transform: uppercase;
  color: #006039;
  margin-bottom: 0.8rem;
}

.chooseView h1 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 42px;
  line-height: 49px;
  color: #000000;
  margin-bottom: 1.6rem;
}

.chooseView p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  /* or 167% */
  color: #6c6c6c;
  letter-spacing: -0.02em;
}

.divider {
  display: flex;
  border: 0.5px solid #e6e6e6;
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}

.checkView {
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
}

.checkView img {
  width: 20px;
  height: 20px;
  margin-right: 1.6rem;
  /* margin-bottom: 1rem; */
}

.detailView {
  display: flex;
  flex-direction: column;
  margin-top: 16rem;
}

.detailView h2 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.639344px;
  color: #666666;
  margin-bottom: 0.8rem;
}

.detailView p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.639344px;
  color: #666666;
}
.kvn-about_us-right_arrow img {
  width: 1.7rem;
  height: 1rem;
}

.kvn-about-viewers-main_container {
  width: 59rem;
  height: 50rem;
}

@media screen and (max-width: 1450px) {
  .usersTopDiv {
    justify-content: center;
  }
  .detailView {
    margin-top: 6rem;
  }
}

@media screen and (max-width: 1100px) {
  .kawan-about_us-main-image-container h1 {
    margin-top: 25rem;
    font-size: 5.2rem;
    margin-bottom: 3rem;
  }
  .kawan-about_us-main-image-container p {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 850px) {
  .kawan-about_us-main-image-container h1 {
    margin-top: 15rem;
    font-size: 4.2rem;
  }
  .kawan-about_us-main-image-container p {
    font-size: 2rem;
  }
}

/* @media screen and (max-width: 850px) {
  .mianContainer {
    padding: 4.8rem 7rem;
  }
  
} */

@media screen and (max-width: 620px) {
  /* .mianContainer {
    padding: 3.8rem 5rem;
  } */
  .aboutTextDiv h1 {
    font-size: 4.8rem;
    line-height: 4rem;
  }
  .contactDiv {
    width: 29rem;
  }
}

@media screen and (max-width: 620px) {
  .kawan-about_us-who_is-professional-heading h3 {
    font-size: 2rem;
  }

  .kawan-carousal-btn-back {
    left: 33% !important;
  }

  .kawan-carousal-btn-for {
    left: 53% !important;
  }
  .kawan-about_us-main-image-container h1 {
    margin-top: 15rem;
    font-size: 3.4rem;
  }
  .kawan-about_us-main-image-container p {
    width: 50rem;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 560px) {
  .kawan-about_us-main-image-container h1 {
    margin-top: 3rem;
    font-size: 3.2rem;
  }
  .kawan-about_us-main-image-container p {
    font-size: 1.6rem;
    margin-bottom: 1rem;
    width: 40rem;
  }
}

@media screen and (max-width: 430px) {
  .kawan-about_us-main-image-container h1 {
    margin-top: 3rem;
    font-size: 2.2rem;
  }
  .kawan-about_us-main-image-container p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    width: 30rem;
  }
}

/* @media screen and (max-width: 480px) {
  .aboutTextDiv h1 {
    font-size: 2.8rem;
    line-height: 2rem;
  }
  .verifiedDiv h1 {
    font-size: 15px;
    line-height: 17px;
  }
  .verifiedDiv {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
  .userDetailView h2 {
    font-size: 11px;
    line-height: 12px;
  }
  .userDetailView p {
    font-size: 10px;
    line-height: 14px;
  }
  .verifiedDiv img {
    width: 35px;
    height: 36px;
    margin-right: 0.6rem;
  }
  .userDetailView {
    margin-top: 1.4rem;
  }
  .verifiedDiv {
    width: 19.2rem;
    padding-left: 2rem;
  }
  .seeMoreDiv {
    margin-top: 0.8rem;
  }
  .verifiedLogo {
    left: 15rem;
  }
  .contactDiv {
    left: 11rem;
  }
  .contactDiv img {
    width: 46px;
    height: 46px;
    border-radius: 24px;
    margin-bottom: 0.6rem;
  }
  .contactDiv h2 {
    font-size: 14px;
    line-height: 15px;
  }
  .contactDiv p {
    font-size: 12px;
    line-height: 9px;
  }
  .contactDiv {
    width: 18rem;
  }
  .kvn-about-viewers-main_container {
    width: 27rem;
    height: 36rem;
  }
  .chooseView h1 {
    font-size: 26px;
    line-height: 27px;
  }
  .chooseView p {
    font-size: 16px;
    line-height: 17px;
  }
  .chooseView h2 {
    font-size: 23px;
    line-height: 21px;
  }
  .seeMoreDiv {
    margin-top: 0.8rem;
    width: 8rem;
  }
  .seeMoreDiv h4 {
    font-size: 12px;
    line-height: 16px;
  }
  .detailView p {
    font-size: 14px;
    line-height: 17px;
  }
  .kvn-about_us-right_arrow img {
    width: 1.5rem;
    height: 0.9rem;
  }
} */
