.reschedule-container * {
  color: #056038;
}

.kwn_reschedule_appointment_reasons_top_view {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}

.kwn_reschedule_appointment_reasons_top_view h2 {
  margin-bottom: 10px;
  font-family: Satoshi;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.1rem;
  display: flex;
  align-items: center;
  /* text-transform: uppercase; */
}

.kwn_reschedule_appointment_reasons_view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.kwn_reschedule_appointment_reasons {
  width: 45%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.kwn_reschedule_appointment_reasons {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  align-items: center;
}

.kwn_reschedule_appointment_reasons img {
  width: 1rem;
  height: 1rem;
  margin-right: 1.4rem;
}

.kwn_reschedule_appointment_reasons h2 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.8rem;
  text-transform: none;
}

.font-12 * {
  font-size: 12px;
}

.font-15 * {
  font-size: 17px;
}

.satoshi * {
  font-family: Satoshi;
}

.kwn_reschedule_appointment_inputs_top_view {
  display: flex;
  margin-top: 1rem;
  width: 98%;
}

.kwn_reschedule_appointment_button_view {
  margin-bottom: 2rem;
  width: 95%;
  margin-top: 3rem;
  display: flex;
  justify-content: flex-end;
}

.kwn-reschedule_appointment_success_top_view {
  display: flex;
  margin-top: 3rem;
  justify-content: center;
}

.kwn-reschedule_appointment_success_view {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.kwn-reschedule_appointment_success_view img {
  width: 14.4rem;
  width: 12.2rem;
  padding-bottom: 5rem;
}

.kwn-reschedule_appointment_success_view h1 {
  font-family: Satoshi;
  font-weight: 500;
  font-size: 3.3rem;
  line-height: 4.2rem;
}

.kwn-reschedule_appointment_success_view h3 {
  font-family: Satoshi;
  font-weight: 400;
  font-size: 2.2rem;
  line-height: 2.6rem;
  display: flex;
  align-items: center;
  max-width: 41.9rem;
  margin-top: 1.8rem;
}

.kwn-reschedule_appointment_appointment_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4.6rem;
  align-self: center;
}

.kwn-reschedule_appointment_cancel_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  align-self: center;
}

.kwn_reschedule_appointment_reasons_view h1 {
  font-family: Satoshi;
  font-weight: 500;
  font-size: 3.3rem;
  line-height: 4.2rem;
}

.kwn_reschedule_appointment-nueva h1 {
  font-family: Satoshi;
  font-weight: 500;
  font-size: 2.8rem;
  line-height: 5.6rem;
}
