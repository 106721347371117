.button_container_patient {
  width: 100%;
  display: flex;
  /* width: 18.1rem; */
  /* margin-right: 100px; */
  height: 5.5rem;
  border: 1px solid #006039;
  background-color: #006039;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  /* margin-top: 3.2rem; */
  align-self: center;
  cursor: pointer;
}

.button_container_patient p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.5rem;
  color: #fff;
  margin: 0;
  font-weight: 700;
}

@media screen and (max-width: 480px) {
  .button_container_patient {
    width: 10.2rem;
    height: 4.1rem;
  }

  .button_container_patient p {
    font-size: 1.4rem;
    line-height: 1.5rem;
  }
}
