.kavan__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.4rem 9.6rem;
  /* background-color: var(--color-theme); */
}

.kavan__navbar-links_logo {
  margin-right: 3.2rem;
}



.otpHeading{
  font-size: 20px;
}

.kavan__navbar-links_logo img {
  width: 15.4rem;
  height: 5.2rem;
  cursor: pointer;
  object-fit: contain;
}

.kavan__navbar * {
  color: #3A3653 !important;
  font-weight: bold !important;
}

.kavan__navbar-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.kavan_navbar-links_container {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.kavan__navbar-links_text {
  color: white;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  margin: 0rem 3.2rem;
  cursor: pointer;
  /* padding-bottom: 1rem; */
  border-bottom-width: 2px;
}

.kavan__navbar-links_button {
  border: 2px solid #120D30 !important;
  padding: 1.6rem 3.2rem;
  border-radius: 1.4rem;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 25px;
  margin: 0rem 3.2rem;
  cursor: pointer;
  outline: none;
  border: none;
  /* width: 25.9rem; */
  height: 4.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.kavan__navbar_closeIcon {
  width: 20px;
  height: 20px;
}

.kavan__navbar_menuIcon {
  width: 30px;
  height: 30px;
}

.kavan__navbar-menu {
  margin-left: 1.6rem;
  display: none;
  position: relative;
}

.kavan__navbar-menu img {
  cursor: pointer;
}

.kavan__navbar-menu_container {
  display: flex;
  justify-content: flex-start;
  /* align-items: flex-start; */
  flex-direction: column;
  z-index: 100;
  text-align: end;
  background-color: var(--color-theme);
  padding: 3.2rem;
  position: absolute;
  top: 2.2rem;
  right: -1.8rem;
  margin-top: 2.4rem;
  min-width: 300px;
  border-radius: 5px;
  box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
  /* transform: translateX(100%); */
  transition: transform 0.3s ease-out;
  height: 100vh;
}

.open {
  transform: translateX(0%);
}

.closed {
  transform: translateX(100%);
}

@keyframes slideLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media screen and (max-width: 1260px) {
  .kavan__navbar-links {
    display: none;
  }

  .kavan__navbar-menu {
    display: flex;
  }
  .kavan__navbar-links_button {
    font-size: 1.2rem;
    height: 3.4rem;
    margin-top: 3rem;
    display: flex;
    align-self: flex-end;
    margin: 0;
  }
}

@media screen and (max-width: 700px) {
  .kavan__navbar {
    padding: 1.6rem 3.2rem;
  }
}

@media screen and (max-width: 550px) {
  .kavan__navbar {
    padding: 1rem;
  }
  .kavan__navbar-links_logo img {
    width: 3.4rem;
    height: 2.2rem;
  }
  .top-bar-set {
    padding: 1rem 2rem;
  }
  .kavan__navbar_menuIcon {
    width: 2rem;
    height: 2rem;
  }
}

@media screen and (max-width: 480px) {
  .kavan__navbar-menu_container_links p {
    margin: 1.6rem;
    margin-right: 0;
  }
  .kavan__navbar-links_text {
    margin-right: 0;
  }
}
