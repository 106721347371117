.button {
  display: flex;
  width: 38.1rem;
  height: 6.1rem;
  /* border: 1px solid #006039; */
  background-color: #006039;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  /* margin-top: 3.2rem; */
  align-self: center;
  cursor: pointer;
}

.button_container p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.5rem;
  color: #fff;
  margin: 0;
  font-weight: 700;
}

@media screen and (max-width: 480px) {
  .button_container {
    width: 10.2rem;
    height: 4.1rem;
  }

  .button_container p {
    font-size: 1.4rem;
    line-height: 1.5rem;
  }

  .button {
    display: flex;
    width: 28.1rem;
    padding: 20;
    margin-left: 20;
    height: 6.1rem;
    /* border: 1px solid #006039; */
    background-color: #006039;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    /* margin-top: 3.2rem; */
    align-self: center;
    cursor: pointer;
  }
}
