.button_container1 {
  display: flex;
  width: 20.1rem;
  height: 5rem;
  border: 1px solid #006039;
  background-color: #006039;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  /* margin-top: 3.2rem; */
  align-self: center;
  cursor: pointer;
}

.button_container p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.5rem;
  color: #fff;
  margin: 0;
  font-weight: 700;
}

@media screen and (max-width: 480px) {
  .button_container1 {
    display: flex;
  width: 10.2rem;
  height: 4.1rem;
  border: 1px solid #006039;
  background-color: #006039;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  /* margin-top: 3.2rem; */
  align-self: center;
  cursor: pointer;
  }

  .button_container p {
    font-size: 1.4rem;
    line-height: 1.5rem;
  }
}
