.mainContainer {
  width: 100%;
  height: 100%;
  background-color: red;
}

.top-bar-set {
  padding: 3rem 4rem;
}

.home-btn-margin {
  margin-top: 5rem;
}

/*Therapy for all section--------- */

.kawan-home-main-image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.kawan-home-main-image h1 {
  padding-top: 52rem;
  font-size: 6.4rem;
  color: #fff;
  font-family: var(--font-family);
  font-weight: 500;
}

.kawan-home-main-image p {
  padding-top: 4.5rem;
  font-size: 2.8rem;
  font-weight: 300;
  color: #fff;
  font-family: var(--font-family);
  text-align: center;
}

.kawan-home-app-download-image-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6.4rem;
  margin-bottom: 28rem;
  justify-content: space-between;
  width: 56rem;
}
.kawan-home-app-download-image-container img {
  width: 24rem;
  height: 5.4rem;
}
.kawan-home-app-comparison-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-top: 4.7rem;
}
.kawan-home-app-comparison-container img {
  width: 80%;
  /* height: 100%; */
}
.kawan-home-app-source-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.kawan-home-app-source-container p {
  font-size: 1.6rem;
  font-weight: 400;
  color: #666;
  font-family: var(--font-family);
}

.kawan-home-app-source-container span {
  font-size: 1.6rem;
  font-weight: 400;
  color: #005368;
  font-family: var(--font-family);
}
.kawan-home-wave img {
  width: 100%;
  height: 100%;
  margin-top: 1.5rem;
}
.kawan-home-session-online {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.kawan-home-session-online p {
  font-size: 4.8rem;
  font-weight: 400;
  color: #3d5a80;
  font-family: var(--font-family);
  margin-top: 2rem;
}

.kawan-home-session-online span {
  font-size: 4.8rem;
  font-weight: 600;
  color: #3d5a80;
  font-family: var(--font-family);
}
.kawan-home-session-main-container {
  display: flex;
  flex-direction: row;
  margin-top: 9rem;
  justify-content: space-between;
  flex-wrap: wrap;
}

.kawan-hr1-container {
  display: none;
  width: 100%;
  padding: 0% 5%;
  align-items: center;
  justify-self: center;
}
.kawan-hr1-container img {
  width: 100%;
  margin-top: 5%;
}

.kawan-home-session-mobile-ss {
  display: flex;
  flex: 1;
  /* align-items: center; */
  justify-content: center;
}


.kawan-home-session-info-container {
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 5rem;
  margin-top: 3rem;
  margin-left: 6rem;
}

.kawan-home-session-mobile-ss img {
  width: 55rem;
  height: 70rem;
  align-self: flex-end;
  margin-left: 20rem;
}

.kawan-home-session-vertical-line {
  border-left: 1px solid #000000;
  /* align-self: center; */
  width: 1px;
  opacity: 0.5;
}
.kawan-home-session-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.kawan-home-session-item img {
  width: 14rem;
  height: 14rem;
}

.kawan-home-session-item h2 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 23px;
  line-height: 29px;
  color: #000000;
  width: 47rem;
  margin-bottom: 3rem;
}
.kawan-home-ellipse {
  position: relative;
}

.kawan-home-ellipse1 img {
  width: 100%;
  margin-top: 7.7rem;
}
.kawan-home-ellipse2 img {
  display: none;
  width: 100%;
  margin-top: 7.7rem;
}

.kawan-home-ellipse-btn-main-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 14%;
  bottom: 24%;
}

.elliptical {
  width: 200px; /* Adjust to desired width */
  height: 100px; /* Adjust to desired height */
  background-color: #f00; /* Adjust to desired color */
  border-radius: 100% / 0 0 100% 50%; /* Adjust to desired elliptical shape */
  overflow: hidden; /* Clip the top portion of the element */
}

.kawan-join-btn-main-container {
  width: 27rem;
  height: 6.6rem;
  background-color: #fff;
  border: 1.1361px solid #006039;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 3rem;
  cursor: pointer;
}

.kawan-join-btn-main-container p {
  color: #414141;
  font-size: 1.8rem;
  font-family: var(--font-family);
}
/* ----------------Media Query */

/* --------------------------- */
.home-view img {
  width: 100%;
  height: 100%;
}
.kawan-therapy_all-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background-color: "#F7FCFA";
  position: relative;
}
.kawan-therapy_all-container {
  position: absolute;
  top: 32rem;
  margin-left: 7rem;
}

.kawan-therapy_all-container h1 {
  font-size: 7.6rem;
  font-family: var(--font-family);
  font-weight: 500;
}

.kawan-therapy_all-img-container img {
  width: 60rem;
  margin-top: 20rem;
}

.download-app-container {
  width: 38.6rem;
  height: 5.5rem;
  background: #9bd58b;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  margin-top: 5rem;
  cursor: pointer;
}

.download-app-container p {
  font-size: 2rem;
  font-family: var(--font-family);
  font-weight: 500;
  color: #fff;
}

.kawan-therapy_all-container img {
  width: 8.2rem;
  height: 6rem;
  position: absolute;
  right: 0rem;
  bottom: 3rem;
}

.kawan-therapy_all-dot-img-container img {
  width: 70rem;
  /* background-color: red; */
}
.kawan-therapy_all-dot-img-container {
  position: absolute;
}

.kawan-comparison-container {
  width: 100%;
  height: 70rem;
  background-color: #006039;
  border-radius: 50%;
}

.kavan-option-container img {
  width: 100%;
  height: 100%;
}

.header_main_view {
  display: flex;
  background-color: #0060391a;
}

.header_content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 8rem;
}

.header_content h1 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 56px;
  line-height: 66px;

  /* Text Heading */

  color: #2d292a;
}

.header_content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #2d292a;
  margin-top: 2.4rem;
}

.button_view {
  display: flex;
  margin-top: 3.2rem;
  width: 38rem;
  justify-content: space-between;
}

.header_image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header_image img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1260px) {
  .header_main_view {
    flex-direction: column;
  }

  .header_content {
    margin: 0 0 4.8rem;
  }
}

@media screen and (max-width: 650px) {
  .header_content h1 {
    font-size: 45px;
    line-height: 50px;
  }

  .header_content p {
    font-size: 22px;
    line-height: 26px;
  }
}

.body_view {
  display: flex;
  padding: 8rem 12.8rem;
}

.body_content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 8rem;
}

.body_image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.body_image img {
  width: 100%;
  height: 100%;
}

.body_text_one {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  color: #006039;
}

.body_text_two {
  text-align: center;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 42px;
  line-height: 49px;
  padding-top: 3.2rem;

  color: #2d292a;
}

.body_text_three {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
  color: #2d292a;
  padding-top: 4rem;
}

.body_therapy_icon_view {
  font-family: var(--font-family);
  display: flex;
  background-color: #f5f5f5;
  border-radius: 20px;
  width: 552px;
  height: 120px;
  align-items: center;
  margin-top: 4.8rem;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  padding: 0 3.2rem;
}

.body_therapy_icon_view img {
  width: 80px;
  height: 80px;
  margin-right: 3.2rem;
}

@media screen and (max-width: 1260px) {
  .top-bar-set {
    padding: 0;
  }
  .body_view {
    flex-direction: column;
    align-items: center;
  }

  body_image2 {
    flex-direction: column;
  }

  .body_content {
    margin: 0 0 4.8rem;
  }
}

@media screen and (max-width: 750px) {
  .body_therapy_icon_view {
    width: 400px;
    font-size: 26px;
    line-height: 32px;
  }

  .body_image2 {
    margin-top: 3.2rem;
  }

  .body_text_one {
    font-size: 22px;
    line-height: 28px;
  }

  .body_text_two {
    font-size: 36px;
    line-height: 42px;
  }

  .body_text_three {
    font-size: 20px;
    line-height: 26px;
  }

  .optionsView {
    flex-direction: column;
  }

  .verticalDivider {
    display: none;
    border: 1.5px solid red;
  }

  .optionsContent {
    margin-top: 3.2rem;
  }
}

@media screen and (max-width: 490px) {

  .kavan_admin_main_container:has(> .loader-main) {
    padding: 0px !important
  }

  .blog-main-container:has(> .loader-main) {
    margin: 0 !important;
    padding: 0 !important;
  }

  .loader-main {
    width: 100vw !important;
    position: fixed;  
  }

  .body_therapy_icon_view {
    width: 250px;
    font-size: 22px;
    line-height: 28px;
  }

  .body_image2 {
    margin-top: 3.2rem;
  }

  .body_text_one {
    font-size: 22px;
    line-height: 24px;
  }

  .body_text_two {
    font-size: 30px;
    line-height: 35px;
  }

  .body_text_three {
    font-size: 20px;
    line-height: 25px;
  }
}

.body_image2 {
  flex: 1;
  display: flex;
  /* align-items: center;
    justify-content: center; */
}

.body_image2_1_view {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  bottom: 0rem;
  left: 8rem;
  /* bottom: 10px; */
}

.body_image2_1 {
  width: 100%;
  height: 80%;
  border-radius: 30px;
}

.body_image2_2_view {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.body_content2 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.helpView {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  padding-bottom: 3.2rem;
}

.optionsView {
  display: flex;
  padding: 6.4rem 9.6rem;
  justify-content: space-between;
  background-color: #0060391a;
}

.dotStyle {
  width: 5px;
  height: 5px;
  border-radius: 3px;
  margin-top: 0.8rem;
  background-color: black;
  margin-right: 3.2rem;
  align-self: flex-start;
}

.dotTopView {
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-top: 3.2rem;
}

.verticalDivider {
  border: 1.5px solid #006039;
  transform: rotate(180deg);
  margin-top: 3.2rem;
  margin-right: 9.6rem;
  margin-left: 9.6rem;
}

.availalbeTopView {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  align-items: center;
  justify-content: center;
  padding: 9.6rem 6.4rem;
}

.patientsTopView {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 4.8rem 9.6rem;
  flex-wrap: wrap;
}

.patientView {
  box-sizing: border-box;
  width: 320px;
  min-width: 220px;
  /* height: 325px; */
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(57, 61, 72, 0.1);
  border-radius: 12px;
  align-items: center;
  /* justify-content: center; */
  display: flex;
  flex-direction: column;
  margin-bottom: 6.4rem;
  padding: 3.2rem 0rem;
}

.patientView img {
  width: 96px;
  height: 96px;
  border-radius: 50px;
}

.patientView_star {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.patientView_star img {
  width: 14px;
  height: 14px;
  margin-right: 0.8rem;
}

.patientView_title {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #2d292a;
  margin-top: 3.2rem;
}

.patientView_profession {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  margin-top: 0.32rem;
  color: #666666;
  text-align: center;
}

.patientView_reviews {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  margin-top: 0.32rem;
  color: #666666;
}

.reviewsTopView {
  background-color: #333;
  overflow: auto;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
}

.reviewsView {
  margin-top: 3.2rem;
  box-sizing: border-box;
  width: 533px;
  height: 236px;
  background: #ffffff;
  border: 1px solid #006039;
  border-radius: 20px;
}

@media screen and (max-width: 1260px) {
  .patientView img {
    width: 90px;
    height: 90px;
    border-radius: 50px;
  }

  .patientView_star img {
    width: 14px;
    height: 14px;
    margin-right: 0.8rem;
  }

  .patientView_title {
    font-size: 18px;
    line-height: 21px;
  }

  .patientView_profession {
    font-size: 18px;
    line-height: 21px;
  }

  .patientView_reviews {
    font-size: 14px;
    line-height: 17px;
  }
}

@media screen and (max-width: 750px) {
  .home-btn-margin {
    margin-top: 2rem;
  }
  .patientsTopView {
    justify-content: center;
  }

  .patientView img {
    width: 70px;
    height: 70px;
    border-radius: 50px;
  }

  .patientView_star img {
    width: 14px;
    height: 14px;
    margin-right: 0.8rem;
  }

  .patientView_title {
    font-size: 16px;
    line-height: 21px;
  }

  .patientView_profession {
    font-size: 16px;
    line-height: 21px;
  }

  .patientView_reviews {
    font-size: 12px;
    line-height: 17px;
  }
}
.kwn-home-testimonial-heading-main_container {
  margin: 1rem 10rem 0 10rem;
}
.kwn-home-testimonial-heading-main_container h1 {
  text-align: center;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 42px;
  line-height: 49px;
  padding-top: 3.2rem;
  color: #2d292a;
}

.kvn-home-app-download_text-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;
  padding-bottom: 3.3rem;
}
.kwn-home-testimonial-card-main_container {
  margin-left: 10rem;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: row;
  /* overflow: hidden; */
  overflow-x: scroll;
}

.kvn-home-app-download_by-click-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10rem;
}

.kvn-home-app-download_by-click-photo-container {
  width: 45rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.kvn-home-app-download_by-click-photo-container img {
  width: 21.4rem;
  height: 6.4rem;
  cursor: pointer;
}

.kwn-home-testimonial-card-main_container::-webkit-scrollbar {
  height: 2px;
}

/* Track */
.kwn-home-testimonial-card-main_container::-webkit-scrollbar-track {
  background: "red";
  border-radius: 10px;
}

/* Handle */
.kwn-home-testimonial-card-main_container::-webkit-scrollbar-thumb {
  background: rgb(226, 226, 226);
  border-radius: 10px;
}

/* Handle on hover */
.kwn-home-testimonial-card-main_container::-webkit-scrollbar-thumb:hover {
  background: rgb(229, 229, 229);
}

.back-ground-image-home {
  position: absolute;
  top: 0;
  width: 100%;
}
/* -----------Media Query------------ */
@media screen and (max-width: 1650px) {
  .kawan-home-main-image h1 {
    padding-top: 20rem;
  }
}

/* @media screen and (max-width: 1900px) {
  .back-ground-image-home {
    width: 172rem;
    background-size: cover;
  }
} */
@media screen and (max-width: 1520px) {
  .kawan-home-main-image h1 {
    padding-top: 15rem;
  }
}
@media screen and (max-width: 1450px) {
  .kawan-join-btn-main-container {
    width: 20rem;
    height: 5.6rem;
  }
  .kawan-join-btn-main-container p {
    font-size: 1.6rem;
  }
  .kawan-home-ellipse-btn-main-container {
    left: 16%;
    bottom: 23%;
  }
  .kawan-home-session-mobile-ss img {
    margin-left: 10rem;
  }
  .kawan-home-session-info-container {
    margin-left: 0;
  }
}

@media screen and (max-width: 1420px) {
  /* .back-ground-image-home {
    width: 100%;
    background-size: cover;
  } */
  .kawan-therapy_all-container {
    top: 28rem;
  }

  .kawan-therapy_all-container h1 {
    font-size: 6rem;
  }
  .download-app-container {
    width: 32.6rem;
    height: 5rem;
    margin-top: 2.2rem;
  }
  .download-app-container p {
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 1350px) {
  .kawan-therapy_all-container {
    margin-left: 5rem;
    top: 30rem;
  }
  .kawan-therapy_all-container h1 {
    font-size: 6.5rem;
  }
  .kawan-therapy_all-container img {
    right: -2rem;
    bottom: 1rem;
    width: 6.8rem;
    height: 5rem;
  }
  .download-app-container {
    width: 33.6rem;
    height: 5rem;
    margin-top: 2.2rem;
  }
  .download-app-container p {
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 1050px) {
  .kawan-home-main-image h1 {
    font-size: 4.2rem;
    padding-top: 10rem;
  }
  .kawan-home-main-image p {
    font-size: 2.6rem;
    padding-top: 1.5rem;
  }
  .home-btn-margin {
    margin-top: 3rem;
  }
  .kawan-therapy_all-container {
    top: 28rem;
  }

  .kawan-therapy_all-container h1 {
    font-size: 5rem;
  }
  .download-app-container {
    width: 26.6rem;
    height: 4rem;
    margin-top: 2.2rem;
  }
  .download-app-container p {
    font-size: 1.5rem;
  }

  .kawan-therapy_all-container img {
    right: 0rem;
    bottom: 1rem;
    width: 5rem;
    height: 4.2rem;
    object-fit: contain;
  }
}

@media screen and (max-width: 850px) {
  .kawan-therapy_all-container {
    top: 20rem;
  }

  .kawan-therapy_all-container h1 {
    font-size: 3.2rem;
  }
  .download-app-container {
    width: 16.6rem;
    height: 2.2rem;
    margin-top: 1rem;
    border-radius: 0.4rem;
  }
  .download-app-container p {
    font-size: 0.9rem;
  }

  .kawan-therapy_all-container img {
    right: 0rem;
    bottom: 0.5rem;
    width: 3rem;
    height: 2.2rem;
    object-fit: contain;
  }

  .body_view {
    padding: 8rem 8.8rem;
  }
  .patientsTopView {
    padding: 4.8rem 7.5rem;
    justify-content: center;
  }
  .kwn-home-testimonial-card-main_container {
    margin-left: 5rem;
  }
}

@media screen and (max-width: 850px) {
  .kwn-home-testimonial-card-main_container {
    margin-left: 2rem;
  }
  .availalbeTopView {
    padding: 9.6rem 4.4rem;
  }
}
@media screen and (max-width: 850px) {
  .kawan-home-session-online p {
    font-size: 2.8rem;
  }
  .kawan-home-session-online span {
    font-size: 2.8rem;
  }

  .kawan-home-session-vertical-line {
    display: none;
  }

  .kawan-home-session-item {
    width: 100%;
    justify-content: center;
  }

  .kawan-join-btn-main-container {
    width: 12rem;
    height: 2.8rem;
    border-radius: 0.6rem;
    margin-left: 1rem;
  }
  .kawan-join-btn-main-container p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 620px) {
  .kawan-home-session-item h2 {
    font-size: 1.2rem;
    line-height: 1.4rem;
    width: 16rem;
    margin-bottom: 1rem;
  }
  .home-btn-margin {
    margin-top: 1rem;
  }
  .kawan-home-app-download-image-container {
    width: 100%;
    justify-content: space-around;
    flex-direction: column;
    height: 20rem;
  }
  .kawan-home-app-download-image-container {
    height: 9rem;
  }
  .kawan-home-main-image h1 {
    font-size: 2.4rem;
  }
  .kawan-home-main-image p {
    font-size: 1.6rem;
  }
  .kawan-home-app-download-image-container img {
    width: 12rem;
    height: 2.8rem;
  }
  .kawan-home-app-source-container p {
    font-size: 1rem;
  }
  .kawan-home-app-source-container span {
    font-size: 1rem;
  }
  .kawan-home-session-online p {
    font-size: 1.8rem;
  }
  .kawan-home-session-online span {
    font-size: 1.8rem;
  }

  .kawan-home-session-mobile-ss img {
    width: 18rem;
    height: 35rem;
    aspect-ratio: 1;
  }
  .kawan-home-session-item img {
    width: 8rem;
    height: 8rem;
  }
  .kawan-join-btn-main-container {
    width: 8rem;
    height: 2rem;
    border-radius: 0.4rem;
  }
  .kawan-join-btn-main-container p {
    font-size: 0.7rem;
  }
  .kawan-join-btn-main-container span {
    font-size: 0.7rem;
  }
  .kawan-home-ellipse-btn-main-container {
    left: 16%;
    bottom: 20%;
  }
}

@media screen and (max-width: 450px) {
  .kawan-join-btn-main-container {
    width: 7rem;
    height: 2rem;
    border-radius: 0.4rem;
  }
  .kawan-join-btn-main-container p {
    font-size: 0.6rem;
  }
  .kawan-join-btn-main-container span {
    font-size: 0.6rem;
  }
}

@media screen and (max-width: 590px) {
  .kawan-therapy_all-container {
    top: 15rem;
    margin-left: 2rem;
  }
  .kwn-home-testimonial-card-main_container {
    margin-left: 0rem;
  }

  .body_view {
    padding: 8rem 3rem;
  }
  .availalbeTopView {
    padding: 9.6rem 3.4rem;
  }
  .kwn-home-testimonial-heading-main_container {
    margin: 1rem 1rem 0 1rem;
  }
  .optionsView {
    padding: 3.4rem 4.6rem;
  }

  .kwn-home-testimonial-heading-main_container h1 {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }

  .kwn-home-testi-comp-text-container p {
    font-size: 1.8rem;
    line-height: 1.8rem;
  }
  .kvn-home-app-download_by-click-photo-container {
    width: 36rem;
  }
  .kvn-home-app-download_by-click-photo-container img {
    width: 15.4rem;
    height: 4.6rem;
  }
  .kawan-home-session-mobile-ss img {
    margin-left: 0rem;
  }
}

@media screen and (max-width: 490px) {
  .kawan-home-app-comparison-container {
    display: none;
  }
  .kawan-home-ellipse1 img {
    display: none;
  }
  .kawan-home-ellipse2 img {
    display: flex;
  }
  .kawan-hr1-container {
    display: flex;
  }
  .kawan-therapy_all-container {
    top: 12rem;
  }

  .kawan-home-main-image h1 {
    font-size: 2rem;
    padding-top: 5rem;
  }
  .kawan-home-main-image p {
    font-size: 1.2rem;
  }

  .kawan-therapy_all-container h1 {
    font-size: 2.2rem;
  }
  .download-app-container {
    width: 10.6rem;
    height: 1.2rem;
    margin-top: 1rem;
    border-radius: 0.2rem;
  }
  .download-app-container p {
    font-size: 0.6rem;
  }

  .kawan-therapy_all-container img {
    right: 0rem;
    bottom: 0.5rem;
    width: 2rem;
    height: 1.8rem;
    object-fit: contain;
  }

  .header_content h1 {
    font-size: 34px;
    line-height: 40px;
  }
  .availalbeTopView {
    padding: 4.6rem 2.4rem;
  }
  .patientView {
    margin-bottom: 3rem;
  }
  .button_view {
    width: 24rem;
    margin-top: 1.2rem;
  }
  .section__padding {
    padding: 5.6rem 2.4rem;
  }

  .header_content p {
    font-size: 16px;
    line-height: 25px;
  }
  .body_image img {
    width: 74%;
    height: 74%;
  }
  .kawan-home-session-mobile-ss img {
    margin-left: 0rem;
  }
}
@media screen and (max-width: 420px) {
  .kwn-home-testimonial-heading-main_container h1 {
    font-size: 2.2rem;
  }
  .body_text_three {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .kawan-home-main-image h1 {
    font-size: 1.8rem;
    padding-top: 3rem;
  }

  .kawan-home-main-image p {
    font-size: 0.8rem;
    margin-top: 0rem;
  }
  .kawan-home-session-online p {
    font-size: 1.2rem;
  }
  .kawan-home-session-main-container {
    margin-top: 5rem;
  }

  /* .kawan-home-ellipse img {
    margin-top: 3rem;
  } */
}
@media screen and (max-width: 400px) {
  .kvn-home-app-download_by-click-photo-container {
    width: 30rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .kvn-home-app-download_by-click-photo-container img {
    margin-top: 2rem;
  }
  .optionsView {
    padding: 2.4rem 3.6rem;
  }
}

@media screen and (max-width: 390px) {
  .kawan-join-btn-main-container {
    width: 6rem;
    height: 1.8rem;
    border-radius: 0.3rem;
  }
  .kawan-join-btn-main-container p {
    font-size: 0.5rem;
  }
}
