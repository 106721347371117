.kwn-layout-top_menu-main_container {
  display: none;
}
/* ---------Media Query---------------------- */
@media screen and (max-width: 1250px) {
  .kwn-layout-top_menu-main_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 10rem;
    background: var(--color-theme);
  }
  .kwn-layout-menu-burger-container {
    display: none;
  }
  .kwn-layout-menu-burger-container img {
    width: 3rem;
    height: 3rem;
    cursor: pointer;
  }
  .kwn-layout-profile-image-container img {
    width: 6rem;
    height: 6rem;
    object-fit: cover;
    border-radius: 6rem;
  }
  .kwn-layout-today-recent_chat-container h2,
  .kwn-layout-today-appointment-container h2 {
    font-family: var(--font-family);
    font-size: 1.8rem;
    font-weight: 500;
    color: var(--color-white);
    line-height: 2rem;
  }
  .kwn-layout-today-appointment-container p {
    font-family: var(--font-family);
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--color-white);
    line-height: 2rem;
    text-align: center;
    cursor: pointer;
    margin-top: 0.5rem;
  }
  .kwn-layout-recent_chat-profile-container {
    display: flex;
    flex-direction: row;
    margin-top: 2.5rem;
  }
  .kwn-layout-recent_chat-profile-container img {
    width: 3rem;
    height: 3rem;
    border-radius: 1.5rem;
    margin-right: 1rem;
    object-fit: cover;
  }
  .kwn-layout-notification-bell-container img {
    width: 2.4rem;
    height: 2.8rem;
    margin-right: 2rem;
    cursor: pointer;
  }
  .kwn-layout-notification-bell-container {
    position: relative;
  }
  .kwn-layout-notification-tooltip-container::before {
    position: absolute;
    top: -8px;
    content: "";
    width: 20px;
    height: 20px;
    background: #fff;
    transform: rotate(45deg);
    left: 82%;
    border-radius: 4px;
  }
  .kwn-layout-notification-tooltip-container {
    position: absolute;
    width: 20rem;
    height: 26rem;
    background: #fff;
    right: 0.5rem;
    border-radius: 2rem;
    border: 0.5px solid #006039;
    top: 4rem;
    display: flex;
    animation-timing-function: ease-in;
    transition: 1.5ms;
    z-index: 100;
  }
  .kwn-layout-notification-tooltip-text-container {
    position: absolute;
    margin-top: 1rem;
  }
  .kwn-layout-notification-tooltip-text-container p {
    font-family: var(--font-family);
    font-size: 1.7rem;
    font-weight: 500;
    color: var(--color-theme);
    line-height: 2rem;
    margin: 1rem 0.5rem 0 1rem;
    line-height: 15px;
  }
}
@media screen and (max-width: 1050px) {
  .kwn-layout-menu-burger-container {
    display: inline-block;
    margin-left: 3rem;
  }
  .kwn-layout-recent_chat-profile-container {
    margin-top: 0;
  }
}

@media screen and (max-width: 520px) {
  .kwn-layout-profile-image-container img {
    width: 4rem;
    height: 4rem;
  }
  .kwn-layout-menu-burger-container {
    margin-left: 1rem;
  }
  .kwn-layout-notification-bell-container img {
    margin-right: 1rem;
  }
  .kwn-layout-today-appointment-container p {
    line-height: 1rem;
    font-size: 1rem;
    margin-top: 0.3rem;
    /* margin-bottom: 1.4rem; */
  }
  .kwn-layout-today-recent_chat-container h2,
  .kwn-layout-today-appointment-container h2 {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
  .kwn-layout-recent_chat-profile-container img {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
  }

  .kwn-layout-notification-bell-container {
    position: relative;
    margin-bottom: 1.2rem;
  }
  .kwn-layout-top_menu-main_container {
    height: 7rem;
  }
}
