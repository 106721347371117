.kwn-input_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  /* width: 80rem; */
}

.kwn-input_container p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.9rem;
  line-height: 2.4rem;
  color: #666666;
  margin-bottom: 0.9rem;
}

/* #patient_input_container {
  width: unset !important
} */

#paitnet_kwn-input_container_input {
  width: 100% !important;
}

.kwn-input_container_input {
  /* width: 45rem; */
  width: 40rem !important;
  height: 5px !important;
  /* border-radius: 20rem; */
  border-radius: 20px;
  border: 1px solid #666666;
  padding: 2.5rem 2.8rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.kwn-input_container input {
  width: 100%;
  outline: none;
  border: none;
  background: #e4efeb;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.9rem;
  line-height: 1.8rem;
  color: #666666;
}
.kwn-input_container input::placeholder {
  background: #e4efeb;
}
.kwn-input_container img {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.kwn-input_container textarea {
  width: 40rem;
  height: 18rem;
  border-radius: 1.2rem;
  border: 1px solid #666666;
  padding: 1.5rem 2.8rem;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.4rem;
  outline: none;
  resize: none;
  line-height: 1.8rem;
  color: #666666;
}

.kwn-dropdown_container {
  position: absolute;
  border: 1px solid #666666;
  background-color: rgb(255, 255, 255);
  top: 7.8rem;
  right: 0;
  width: 40rem;
  border-radius: 1rem;
  /* height: 20rem; */
  max-height: 20rem;
  z-index: 10;
  overflow: scroll;
  padding: 1rem 2rem;
  cursor: pointer;
}

.kwn-dropdown_container::-webkit-scrollbar {
  height: 2px;
}

/* Track */
.kwn-dropdown_container::-webkit-scrollbar-track {
  background: "red";
  border-radius: 10px;
}

/* Handle */
.kwn-dropdown_container::-webkit-scrollbar-thumb {
  background: rgb(226, 226, 226);
  border-radius: 4px;
}

/* Handle on hover */
.kwn-dropdown_container::-webkit-scrollbar-thumb:hover {
  background: rgb(229, 229, 229);
}

.kwn-dropdown_container p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.9rem;
  line-height: 2.4rem;
  color: #666666;
  margin-bottom: 0.5rem;
}

/* .kwn-input_container input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 1;
  background: none no-repeat;
  content: url(../../assets/calender.png);
}

.kwn-input_container input[type="time"]::-webkit-calendar-picker-indicator {
  opacity: 1;
  background: none no-repeat;
  content: url(../../assets/clock.png);
} */

@media screen and (max-width: 600px) {
  .kwn-input_container {
    width: 40rem !important;
  }
  .kwn-input_container_input {
    width: 40rem !important;
  }
}

@media screen and (max-width: 480px) {
  .kwn-input_container {
    width: 33rem !important;
  }
  .kwn-input_container_input {
    width: 33rem !important;
    padding: 1.6rem 1.8rem;
  }

  .kwn-input_container {
    width: 33rem;
  }

  .kwn-dropdown_container {
    width: 33rem;
  }

  .kwn-input_container textarea {
    width: 33rem;
  }

  .kwn-input_container p {
    font-size: 1.4rem;
    line-height: 1.4rem;
  }
  .kwn-input_container input {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 400px) {
  .kwn-input_container {
    width: 30rem !important;
  }
  .kwn-input_container_input {
    width: 30rem !important;
    padding: 1.6rem 1.8rem;
  }

  .kwn-input_container {
    width: 30rem;
  }

  .kwn-dropdown_container {
    width: 30rem;
  }

  .kwn-input_container textarea {
    width: 30rem;
  }
}

@media screen and (max-width: 350px) {
  .kwn-input_container_input {
    width: 30rem;
  }

  .kwn-input_container {
    width: 30rem;
  }

  .kwn-input_container textarea {
    width: 30rem;
  }

  .kwn-dropdown_container {
    width: 30rem;
  }
}
