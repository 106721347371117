.react-btn-main-container {
  width: 27rem;
  height: 6.6rem;
  background-color: #3d5a80;
  border-radius: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.react-btn-main-container h4 {
  font-family: var(--font-family);
  font-size: 2.6rem;
  font-weight: 400;
  color: #fff;
}

/* -------------media Query--------- */
@media screen and (max-width: 1450px) {
  .react-btn-main-container {
    width: 20rem;
    height: 5.6rem;
  }
  .react-btn-main-container h4 {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 850px) {
  .react-btn-main-container {
    width: 12rem;
    height: 2.8rem;
    border-radius: 0.6rem;
  }

  .react-btn-main-container h4 {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 620px) {
  .react-btn-main-container {
    width: 8rem;
    height: 2rem;
    border-radius: 0.4rem;
  }
  .react-btn-main-container h4 {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 450px) {
  .react-btn-main-container {
    width: 7rem;
    height: 2rem;
    border-radius: 0.4rem;
  }
  .react-btn-main-container h4 {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 390px) {
  .react-btn-main-container {
    width: 6rem;
    height: 1.8rem;
    border-radius: 0.3rem;
  }
  .react-btn-main-container h4 {
    font-size: 0.7rem;
  }
}
