.patient-header-details {
    display: flex;
    width: 100%;
    align-content: center;
    align-items: center;
}


.patient-header-buttons {
    display: flex;
    align-content: center;
    justify-content: center;
    gap: 12px;
}

.pdf-download-info {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 1.5rem;
    margin-bottom: 2px;
}

.icon-send-message-box {
    height: 42px;
    margin-left: 28px;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-top: 4px;

    width: 62px;
}

.icon-send-message {
    height: 32px;
    width: 32px;
}

.detail-section-container {

    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin-top: 28px;
    /* overflow-x:scroll; */
}

.detail-section-sessions
{
    overflow-x:hidden;
}



.detail-section-heading {
    font-family: var(--font-family);
    font-size: 1.5rem;
    margin-bottom: 16px;
    font-weight: 600;
    color: #2F686A;
}

.detail-section-row {

    display: flex;
    justify-content: space-between;
    border: 1px solid #ddd;
    padding: 12px;
    
}

@media (max-width: 768px) {
    .detail-section-row {
        width:80vw;
    }

    .detail-section-sessions
{
    overflow-x:scroll;
}
}

.detail-section-input {
    margin-bottom: 16px;
    font-family: var(--font-family);
    font-size: 1.4rem;
    display: flex;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #6D9597;
    padding: 12px;
}



.detail-section-primary-text {
    font-family: var(--font-family);
    font-size: 1.4rem;
    font-weight: 500;
    color: #2F686A;
}

.detail-section-text {
    font-family: var(--font-family);
    font-size: 1.3rem;
    font-weight: 300;
    color: #6D9597;
}