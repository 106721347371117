.kawn-add-phone_number {
  width: 100%;
  /* height: 50rem; */
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 8.6rem;
}

.PhoneInputCountryIcon {
  width: 5rem !important;
  height: 5rem !important;
}

.PhoneInputCountryIconImg {
  width: 3rem !important;
  height: 2rem !important;
}
.PhoneInputCountryIcon--border {
  width: 11.3rem !important;
  height: 7.5rem !important;
  border-radius: 5rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-wrap: wrap !important;
  background-color: #fff !important;
  /* border: 1px solid #666666 !important; */
}

.PhoneInputCountrySelectArrow {
  display: none !important;
}

.PhoneInputInput {
  width: 32.5rem;
  height: 7.5rem;
  border-radius: 5rem;
  margin-left: 2rem;
  border: 1px solid #666;
  padding-left: 2rem !important;
  font-size: 1.9rem !important;
  color: #666;
}

.kawan-phn-nmbr-btn-container {
  margin-top: 14rem;
  margin-bottom: 10rem;
  /* margin-bottom: 10rem; */
}
