.container-footer-box {
    background-color: #0B081D;
    border-radius: 12px;
    padding-bottom: 5%;
    padding-left: 20px;
    padding-top: 30px;
    padding-right: 20px;
}

.row-footer-view {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ffffff;
}

.icon-social-store {
    height: 34px;
    margin-bottom: 12px;
    cursor: pointer;

}

.link-box-footer {
    width: 150px;
}

.kavan-footer-logo {
    height: 100px;

}

.icon-social-footer {
    height: 25px;
    margin-right: 20px;
    cursor: pointer;
}

.footer-link {
    color: white;
    font-family: var(--font-family);
    font-size: 13px;
    margin-top: 6px;
    font-weight: 300;
    cursor: pointer;
}

.footer-label {
    color: white;
    margin-bottom: 16px;
    font-family: var(--font-family);
    font-size: 15px;
}

.footer-bottom-text {
    color: white;
    font-family: var(--font-family);
    font-size: 12px;
    flex: 2;
}