.kawan-pychologist-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.kawan-pychologist-title-container h1 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 3.2rem;
  color: #2d292a;
  text-align: center;
}

.kawan-pychologist-title-container h2 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.3rem;
  color: #2d292a;
  margin-top: 1rem;
  text-align: center;
}

.kwn-choose-profile-drop-down-container {
  display: flex;
  flex-direction: row;
  width: 100rem;
  justify-content: space-between;
  flex-wrap: wrap;

  /* margin-top: 5.6rem; */
}

.kawa-checkbox {
  width: 15px;
  height: 15px;
  border: 1px solid #2d292a;
  border-radius: 0;
  /* -webkit-appearance: none; */
  outline: none;
  cursor: pointer;
}

.kawan-checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3rem;
  margin-right: 5rem;
}

.kawan-checkbox-container p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.3rem;
  color: #2d292a;
  margin-left: 1.3rem;
}

.kawan-pycholo-skill-select-container {
  width: 100rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.kawan-pycholo-skill-select-container h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 2rem;
  color: #2d292a;
}

.kawan-pyscholo-skill-select-sub-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.kawan-psycholo-term-condition-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.kawan-psycholo-term-condition-container h4 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 2.2rem;
  color: #2d292a;
  margin-left: 1.3rem;
}

.kwn-choose-profile-accept-term {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5.3rem;
}

.kwn-choose-profile-accept-term p {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 22px;
  color: #2d292a;
  margin-left: 2rem;
}

@media screen and (max-width: 1050px) {
  .kwn-choose-profile-drop-down-container {
    justify-content: center;
  }
  .kwn-choose-profile-drop-down-container {
    width: 100%;
  }
  .kawan-pyscholo-skill-select-sub-container {
    flex-direction: column;
  }
  .kawan-pycholo-skill-select-container {
    width: 50rem;
  }
}
@media screen and (max-width: 580px) {
  .kawan-pycholo-skill-select-container {
    width: 40rem;
  }
  .kawan-psycholo-term-condition-container h4 {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 480px) {
  .kawan-pychologist-title-container h1 {
    font-size: 2.2rem;
  }
  .kawan-pychologist-title-container h2 {
    font-size: 1.6rem;
    margin-top: 0rem;
  }
  .kawan-pycholo-skill-select-container {
    width: 35rem;
  }
  .kawan-psycholo-term-condition-container h4 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 420px) {
  .kawan-pychologist-title-container h1 {
    font-size: 1.8rem;
  }
  .kawan-pycholo-skill-select-container {
    width: 33rem;
  }
  .kawan-psycholo-term-condition-container h4 {
    font-size: 1.2rem;
  }
}
